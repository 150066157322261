import React, { useContext, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { routes } from 'lane-shared/config';

import { ContentWorkflowPayloadType } from 'lane-shared/types/ContentWorkflowType';

import styles from './EmailBuilderV2Preview.scss';
import { Button, H4, H5, Icon, M, P, Thumbnail } from 'design-system-web';
import EmailFooter from 'lane-shared/emails/components/EmailFooter';
import { AppContext, UserDataContext } from 'lane-shared/contexts';
import { ChannelType } from 'lane-shared/types/ChannelType';
import RichTextHtmlRenderer from 'lane-shared/components/RichTextRenderer';
import urljoin from 'url-join';
import { convertTo62 } from 'lane-shared/helpers/convertId';
import {
  WorkflowTargetEnum,
  WorkflowWhenContextEnum,
} from 'lane-shared/types/Workflows';
import {
  colorToHex,
  dateFormatter,
  imageUrl,
} from 'lane-shared/helpers/formatters';
import { EmailCheckboxesType, EmailEditableEnum } from '../helpers/constants';
import { EmailOptionsDropdown } from './EmailOptionsDropdown';
import { ContentType } from 'lane-shared/types/content/Content';
import { DraftContentType } from 'lane-shared/types/content/DraftContent';
import {
  createDummyInteraction,
  getEmptyBlockPlaceholder,
  isWorkflowWhenContextContent,
} from '../helpers';
import { LONG_DATE_TIME_WITH_TZ } from 'lane-shared/helpers/constants/dates';
import { Well } from 'components/general';
import EmailButton from 'lane-shared/emails/components/EmailButton';
import { MjmlColumn, MjmlWrapper } from 'lane-shared/emails/MjmlComponentsV2';
import { explodeFeatures } from 'lane-shared/helpers/features';
import mapPropertyDefinition from 'lane-shared/emails/mapPropertyDefinition';
import { DefaultBody } from './PreviewDefaults/DefaultBody';
import { getStandardDefaultTextPayload } from '../helpers/getStandardDefaultTextPayload';
import { DefaultIntro } from './PreviewDefaults/DefaultIntro';
import { Workflow } from 'lane-shared/types/workflow';
import { LanguagePreviewSelector } from 'components/general/LanguagePreviewSelector';
import { useFlag, useMultiLanguage } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { cloneDeep } from 'lodash';

type Props = {
  className?: string;
  style: React.CSSProperties;
  workflow: Workflow;
  channel: ChannelType;
  content: ContentType | DraftContentType;
  timeZone: string;
  setOptionInEditingMode: (option: EmailEditableEnum) => void;
  draftPayloadForEmailSettings: ContentWorkflowPayloadType;
  updateDraftPayloadForEmailSettings: (
    updates: Partial<ContentWorkflowPayloadType>
  ) => void;
  emailCheckboxes: EmailCheckboxesType;
  setEmailCheckboxes: (checkboxes: EmailCheckboxesType) => void;
  onEmailOptionsClose?: () => void;
  onEmailOptionsOpen?: () => void;
};

const EMAIL_OPTION_BOOLS = [
  EmailEditableEnum.ShowQRCode,
  EmailEditableEnum.ShowContentName,
  EmailEditableEnum.ShowDateTime,
];

const TRANSLATION_KEYS = {
  defaultContentName:
    'web.admin.channel.content.workflow.editor.emailBuilder.contentName.default',
  optionsLabel:
    'web.admin.channel.content.workflow.editor.emailBuilder.optionsDropdown.btn.label',
  subjectPreText:
    'web.admin.channel.content.workflow.editor.emailSettings.subject.preText',
  viewBtn:
    'web.admin.channel.content.workflow.editor.emailBuilder.buttons.view',
};

const QR_SIZE = 200;

function EditButton({
  dataCy,
  onClick,
}: {
  dataCy?: string;
  onClick: () => void;
}) {
  return (
    <Button
      dataCy={dataCy}
      className={styles.EditButton}
      variant="secondary"
      onClick={onClick}
    >
      <Icon className={cx(styles.icon)} name="pencil" />
    </Button>
  );
}

export function EmptyBlockPlaceholder({
  firstLine,
  secondLine,
  thirdLine,
  t,
}: {
  firstLine: string;
  secondLine: string;
  thirdLine: string | null;
  t: (key: string) => string;
}) {
  return (
    <Well className={styles.EmptyBlockPlaceholder}>
      <H4 className={styles.emptyBlockPlaceholderHeader}>{t(firstLine)}</H4>
      <div className={styles.message}>
        <M>{t(secondLine)}</M>
        {thirdLine && <M>{t(thirdLine)}</M>}
      </div>
    </Well>
  );
}

export function EmailBuilderV2Preview({
  className,
  style,
  workflow,
  channel,
  content,
  timeZone,
  setOptionInEditingMode,
  draftPayloadForEmailSettings,
  updateDraftPayloadForEmailSettings,
  emailCheckboxes,
  setEmailCheckboxes,
  onEmailOptionsClose,
  onEmailOptionsOpen,
}: Props) {
  const { t, i18n } = useTranslation();
  const isInteractionBasedWorkflow =
    workflow.whenContext === WorkflowWhenContextEnum.UserContentInteraction;

  useEffect(() => {
    const checkBoxes = {
      [EmailEditableEnum.ShowQRCode]: !!qrCodeCheckIn,
      [EmailEditableEnum.ShowContentName]: !!showContentName,
      [EmailEditableEnum.ShowDateTime]: !!showDateTime,
      [EmailEditableEnum.Intro]:
        isInteractionBasedWorkflow || intro ? true : emailCheckboxes.intro,
      [EmailEditableEnum.Outro]: outtro ? true : emailCheckboxes.outtro,
    };
    setEmailCheckboxes(checkBoxes);
  }, [draftPayloadForEmailSettings]);

  const { user } = useContext(UserDataContext);
  const appContext = useContext(AppContext);
  const whiteLabel = useMemo(
    () => ({ ...appContext.whitelabel, baseUrl: appContext.whitelabel.url }),
    []
  );

  const channelDefaultLanguage = channel?.settings?.multiLanguageEnabled
    ? (channel?.settings?.channelLanguages?.primary as string)
    : '';
  const [previewLanguage, setPreviewLanguage] = useState<string>(
    channelDefaultLanguage
  );

  const { translate } = useMultiLanguage();

  const placeholderInteractionWithoutPreview = useMemo(() => {
    if (content?._id && user?._id) {
      return createDummyInteraction({ content, user, channel });
    }
    return undefined;
  }, [content?._id]);

  const placeholderInteractionForPreview = useMemo(() => {
    if (content?._id && user?._id) {
      return createDummyInteraction({
        content: translate({
          model: cloneDeep(content),
          columns: ['name', 'description', 'subtitle'],
          previewLanguage,
        }),
        user,
        channel,
      });
    }
    return undefined;
  }, [content?._id, previewLanguage]);

  let draftPayloadForEmailSettingsForPreview:
    | ContentWorkflowPayloadType
    | undefined;
  let dataFieldsForPreview: ContentType['data'] | undefined;
  let contentForPreview: ContentType | undefined;
  let placeholderInteraction = placeholderInteractionWithoutPreview;

  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );
  const shouldDisplayLanguagePreviewSelector = Boolean(
    isMLSLanguagePreviewSelectorEnabled &&
      channel?.settings?.multiLanguageEnabled
  );

  useEffect(() => {
    setPreviewLanguage(channelDefaultLanguage);
  }, [channelDefaultLanguage]);

  if (!workflow || !channel || !content) {
    return null;
  }

  if (shouldDisplayLanguagePreviewSelector && previewLanguage) {
    placeholderInteraction = placeholderInteractionForPreview;

    draftPayloadForEmailSettingsForPreview = translate({
      model: cloneDeep(draftPayloadForEmailSettings),
      columns: ['intro', 'outtro', 'body', 'text'],
      previewLanguage,
    });

    dataFieldsForPreview = translate({
      model: cloneDeep(content.data),
      previewLanguage,
    });

    contentForPreview = translate({
      model: cloneDeep(content),
      columns: ['name', 'description', 'subtitle'],
      previewLanguage,
    });
  }

  const {
    text: subject,
    intro,
    body,
    outtro,
    showContentName,
    showDateTime,
    qrCodeCheckIn,
  } =
    draftPayloadForEmailSettingsForPreview ||
    draftPayloadForEmailSettings ||
    {};

  function handleCheckEmailOption(option: EmailEditableEnum, value: boolean) {
    if (!Object.values(EmailEditableEnum).includes(option)) {
      return;
    }

    if (EMAIL_OPTION_BOOLS.includes(option)) {
      updateDraftPayloadForEmailSettings({
        ...draftPayloadForEmailSettings,
        [option]: value,
      });
    } else {
      if (!value) {
        setOptionInEditingMode(EmailEditableEnum.None);
      }
      setEmailCheckboxes({
        ...emailCheckboxes,
        [option]: value,
      });
    }
  }

  const {
    firstLine: outroFirstLine,
    secondLine: outroSecondLine,
    thirdLine: outroThirdLine,
  } = getEmptyBlockPlaceholder(EmailEditableEnum.Outro);

  const forAdmin = ![
    WorkflowTargetEnum.InteractionCreator,
    WorkflowTargetEnum.GuestInviteFeature,
  ].includes(workflow.target);

  const receiptInfo =
    placeholderInteraction &&
    content.data &&
    mapPropertyDefinition({
      obj: placeholderInteraction.data,
      definition: dataFieldsForPreview ?? content.data,
      user,
      creatorId: placeholderInteraction.contentData._createdBy || '',
      sourceId: placeholderInteraction.channelId,
      ownerId: placeholderInteraction.userId,
    });

  const contentLink = placeholderInteraction
    ? urljoin(
        whiteLabel.baseUrl,
        forAdmin
          ? routes.channelAdminContent
              .replace(':id', channel.slug)
              .replace(
                ':contentId',
                convertTo62(placeholderInteraction.contentData._id)
              )
          : routes.content.replace(
              ':id',
              convertTo62(placeholderInteraction.contentData._id)
            )
      )
    : undefined;

  const qrCodeLink = placeholderInteraction
    ? urljoin(
        whiteLabel.baseUrl,
        `/api/v5/qr-code/${
          forAdmin ? 'admin-interaction' : 'interaction'
        }/${convertTo62(placeholderInteraction._id)}.png?size=${QR_SIZE}?slug=${
          channel.slug
        }`
      )
    : undefined;

  const handleEmailCustomizableOptionChange = (option: EmailEditableEnum) => {
    if (
      [
        EmailEditableEnum.Intro,
        EmailEditableEnum.Outro,
        EmailEditableEnum.Body,
        EmailEditableEnum.Subject,
      ].includes(option)
    ) {
      setOptionInEditingMode(option);
    }
  };

  const {
    statusesFeature,
    paymentFeature,
    menuFeature,
    guestInviteFeature,
  } = explodeFeatures(content?.features);

  const hideExtraSelectables = isWorkflowWhenContextContent(workflow.event);

  const defaultTextPayload = getStandardDefaultTextPayload({
    channel,
    contentName: content.name,
    workflow,
    userName: user?.profile.name!,
  });

  return (
    <div className={cx(styles.EmailBuilderPreview, className)} style={style}>
      <div className={cx(styles.emailToolBar, className)} style={style}>
        <EmailOptionsDropdown
          options={emailCheckboxes}
          label={t(TRANSLATION_KEYS.optionsLabel)}
          hideExtraSelectables={hideExtraSelectables}
          checkEmailOption={handleCheckEmailOption}
          isInteractionBasedWorkflow={isInteractionBasedWorkflow}
          onClose={onEmailOptionsClose}
          onOpen={onEmailOptionsOpen}
        />

        {shouldDisplayLanguagePreviewSelector && (
          <LanguagePreviewSelector
            previewLanguage={previewLanguage}
            setPreviewLanguage={setPreviewLanguage}
          />
        )}
      </div>

      <div className={cx(styles.emailPreviewScrollable, className)}>
        <div className={cx(styles.hoverable, className)}>
          <div
            className={cx(styles.subjectContainer, className)}
            data-cy="subject"
            style={style}
          >
            <div className={cx(styles.subjectText, className)}>
              <span className={cx(styles.bold, className)}>
                {t(TRANSLATION_KEYS.subjectPreText)}
              </span>
              {subject && subject.length > 0 ? (
                subject
              ) : (
                <span>{defaultTextPayload.text}</span>
              )}
            </div>
            <EditButton
              dataCy="subjectEditButton"
              onClick={() =>
                handleEmailCustomizableOptionChange(EmailEditableEnum.Subject)
              }
            />
          </div>
        </div>

        <div
          className={cx(styles.emailContentContainer, className)}
          style={style}
        >
          <div
            className={cx(styles.companyContainer, className)}
            style={style}
            data-cy="company"
          >
            <div className={cx(styles.companylogo, className)} style={style}>
              <Thumbnail
                className={cx(styles.icon, className)}
                src={
                  whiteLabel.profile?.logo || channel.profile?.logo
                    ? imageUrl(
                        whiteLabel.profile?.logo || channel.profile?.logo
                      )
                    : undefined
                }
                name={whiteLabel.name || channel.name!}
              />
              <div
                className={cx(styles.verticalLine, className)}
                style={style}
              />
              <H5
                className={cx(styles.companyNameText, className)}
                style={style}
              >
                {whiteLabel?.name || channel.name}
              </H5>
            </div>
          </div>

          {emailCheckboxes.intro && (
            <div className={cx(styles.hoverable, className)}>
              <div
                data-cy="introContainer"
                className={cx(styles.editable, styles.introContainer)}
              >
                <div
                  className={cx(styles.intro, className)}
                  style={style}
                  data-cy="intro"
                >
                  {intro ? (
                    <RichTextHtmlRenderer value={intro} />
                  ) : (
                    <DefaultIntro
                      workflow={workflow}
                      forAdmin={forAdmin}
                      whiteLabel={whiteLabel}
                      user={user}
                      i18n={i18n}
                      interaction={placeholderInteraction}
                      content={contentForPreview ?? content}
                      timeZone={timeZone}
                      channel={channel}
                      statusesFeature={statusesFeature}
                      subject={subject}
                    />
                  )}
                </div>
                <EditButton
                  onClick={() =>
                    handleEmailCustomizableOptionChange(EmailEditableEnum.Intro)
                  }
                />
              </div>
            </div>
          )}

          {emailCheckboxes.qrCodeCheckIn && (
            <div className={styles.qrCode} data-cy="qrCodeContainer">
              <img alt="QR Code" width={QR_SIZE} src={qrCodeLink} />
            </div>
          )}

          {emailCheckboxes.showContentName && placeholderInteraction && (
            <div className={styles.contentName} data-cy="contentName">
              <MjmlWrapper data-testid="showContentName" padding={0}>
                <H4>
                  {(user as any)?.isGuest ? (
                    placeholderInteraction.contentData?.name
                  ) : (
                    <a
                      href={contentLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {placeholderInteraction.contentData?.name ??
                        t(TRANSLATION_KEYS.defaultContentName)}
                    </a>
                  )}
                </H4>
              </MjmlWrapper>
            </div>
          )}

          {emailCheckboxes.showDateTime && user && placeholderInteraction && (
            <div className={styles.dateTime} data-cy="dateTime">
              <MjmlWrapper padding={0}>
                <P style={{ textAlign: 'center' }}>{i18n.t('Date and Time')}</P>
                <MjmlColumn>
                  <P style={{ textAlign: 'center' }}>
                    {i18n.t('Starts')}
                    {': '}
                    {dateFormatter(
                      placeholderInteraction.startDate ||
                        placeholderInteraction._created,
                      LONG_DATE_TIME_WITH_TZ,
                      timeZone,
                      user.locale
                    )}
                  </P>
                  {placeholderInteraction.endDate && (
                    <P style={{ textAlign: 'center' }}>
                      {i18n.t('Ends')}
                      {': '}
                      {dateFormatter(
                        placeholderInteraction.endDate,
                        LONG_DATE_TIME_WITH_TZ,
                        timeZone,
                        user.locale
                      )}
                    </P>
                  )}
                </MjmlColumn>
              </MjmlWrapper>
            </div>
          )}

          <div className={cx(styles.hoverable, className)}>
            <div data-cy="bodyContainer" className={styles.editable}>
              <div
                className={cx(styles.body, className)}
                style={style}
                data-cy="body"
              >
                {body ? (
                  <RichTextHtmlRenderer value={body} />
                ) : (
                  <DefaultBody
                    workflow={workflow}
                    forAdmin={forAdmin}
                    receiptInfo={receiptInfo}
                    integrationInfo={null}
                    guestInviteFeature={guestInviteFeature}
                    menuFeature={menuFeature}
                    whiteLabel={whiteLabel}
                    user={user}
                    paymentFeature={paymentFeature}
                    i18n={i18n}
                    interaction={placeholderInteraction}
                    content={contentForPreview ?? content}
                    timeZone={timeZone}
                  />
                )}
                <EmailButton
                  width={150}
                  color={colorToHex(whiteLabel.profile?.backgroundColor)}
                  className={cx(styles.viewButton, className)}
                >
                  {t(TRANSLATION_KEYS.viewBtn)}
                </EmailButton>
              </div>
              <EditButton
                dataCy="bodyEditButton"
                onClick={() =>
                  handleEmailCustomizableOptionChange(EmailEditableEnum.Body)
                }
              />
            </div>
          </div>

          {emailCheckboxes.outtro && (
            <div className={cx(styles.hoverable, className)}>
              <div data-cy="outroContainer" className={styles.editable}>
                <div
                  className={cx(styles.outro, className)}
                  style={style}
                  data-cy="outro"
                >
                  {outtro ? (
                    <RichTextHtmlRenderer value={outtro} />
                  ) : (
                    <EmptyBlockPlaceholder
                      firstLine={outroFirstLine}
                      secondLine={outroSecondLine}
                      thirdLine={outroThirdLine}
                      t={t}
                    />
                  )}
                </div>
                <EditButton
                  onClick={() =>
                    handleEmailCustomizableOptionChange(EmailEditableEnum.Outro)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className={cx(styles.footerText, className)}
        style={style}
        data-cy="footer"
      >
        <EmailFooter
          address={channel.address}
          contactUsEmail={channel.profile?.email || whiteLabel.profile?.email}
          channel={channel}
          i18n={i18n}
          profile={channel.profile}
          unsubscribe={user?._id}
          whiteLabel={whiteLabel}
          divider={false}
          noPadding
          fontSize={12}
        />
      </div>
    </div>
  );
}
