import React, { useState, useContext } from 'react';

import cx from 'classnames';
import { CircleListView } from 'components';
import { useTranslation } from 'react-i18next';

import { revokeAccessManager } from 'lane-shared/graphql/accessControl';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { ChannelsContext } from 'lane-shared/contexts';

import CardContainer from 'components/cards/CardContainer';
import { XS, H5 } from 'components/typography';

import { UserInfo } from '../../../types/AccessControl';
import { ErrorModal } from '../ErrorModal';
import { Button, M, Modal } from 'design-system-web';

import styles from './styles.scss';
import { useMutation } from '@apollo/client';
import { SPECIAL_GROUP_ROLES } from 'lane-shared/helpers/constants/channel';

type Props = {
  channelId: string;
  user: UserInfo;
  isOpen: boolean;
  onClose: (triggerRefresh?: boolean) => void;
};

export const RevokeAccessManagerModal = ({
  channelId,
  user,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { channels } = useContext(ChannelsContext);

  const channel = channels.find((channel: any) => channel._id === channelId);

  const [revokeAccessManagerMutation] = useMutation(revokeAccessManager);

  const isCompanyAccessAdmin = user?.roles?.includes(
    SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN
  );

  const getUserRoleDisplayText = () => {
    if (user?.roles?.includes(SPECIAL_GROUP_ROLES.ACCESS_MANAGER)) {
      return t(
        'web.admin.accessControl.userAccess.modal.revokeAccessManager.accessAdmin'
      );
    }
    if (user?.roles?.includes(SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN)) {
      return t(
        'web.admin.accessControl.userAccess.modal.revokeAccessManager.companyAccessManager'
      );
    }
    return '';
  };

  const [hasError, setHasError] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };

  const handleSaveChanges = async () => {
    try {
      await revokeAccessManagerMutation({
        variables: {
          channelId,
          userId: user._id,
        },
      });
      setHasError(false);
      onClose(true);
      window.Toast.show(
        t(
          'web.admin.accessControl.userAccess.modal.revokeAccessManager.success',
          { fullName: user.name, groupRoleName: getUserRoleDisplayText() }
        )
      );
    } catch (err) {
      setHasError(true);
      console.error(err);
    }
  };

  if (hasError) {
    return (
      <ErrorModal
        title={t(
          'web.admin.accessControl.userAccess.modal.revokeAccessManager.error.title'
        )}
        description={t(
          'web.admin.accessControl.userAccess.modal.revokeAccessManager.error.description',
          { fullName: user.name, groupRoleName: getUserRoleDisplayText() }
        )}
        buttonTitle={t('Retry')}
        isOpen={hasError}
        onClose={onClose}
        onClick={handleSaveChanges}
      />
    );
  }

  return (
    <Modal
      isOpen={!!(isOpen && channel)}
      onClose={handleClose}
      title={t(
        'web.admin.accessControl.userAccess.modal.revokeAccessManager.title',
        {
          fullname: user.name,
          groupRoleName: getUserRoleDisplayText(),
          vowel: isCompanyAccessAdmin ? 'a' : 'an',
        }
      )}
      className={cx(styles.modal)}
      size="large"
      dataCy="revokeAccessManagerModal"
    >
      <div data-cy="access-control-modal">
        <CardContainer>
          <CircleListView
            style={{ padding: '0px' }}
            image={imageUrl(user.image)}
            logo={imageUrl(user.logo)}
            name={user.name}
          >
            <H5>{user.name}</H5>
            <XS variant="secondary">
              {user.companies.length > 1 &&
                `${t(
                  'web.admin.accessControl.userAccess.modal.revokeAccessManager.multipleCompanies'
                )} | `}
              {user.companies.length === 1 && `${user.companies[0].name} | `}
              {user.email}
            </XS>
          </CircleListView>
        </CardContainer>
        <M mt={4}>
          {isCompanyAccessAdmin &&
            t(
              'web.admin.accessControl.userAccess.modal.revokeAccessManager.message.secondary'
            )}
          {!isCompanyAccessAdmin &&
            t(
              'web.admin.accessControl.userAccess.modal.revokeAccessManager.message.primary'
            )}
        </M>
        <div className={cx(styles.assignAccessManagerModalButtons)}>
          <Button
            dataCy="revoke-access-manager-submit-button"
            size="large"
            onClick={handleSaveChanges}
          >
            {t(
              'web.admin.accessControl.userAccess.modal.revokeAccessManager.actionButton'
            )}
          </Button>
          <Button
            variant="text"
            dataCy="revoke-access-manager-cancel-button"
            onClick={handleClose}
            size="large"
          >
            {t('Cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
