import React, { useContext, useState } from 'react';

import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';

import { UserDataContext, ValidationErrorContext } from 'lane-shared/contexts';
import ContentRendererContext from 'lane-shared/contexts/ContentRendererContext';
import getLibraryOptions from 'lane-shared/helpers/getLibraryOptions';
import {
  getPackagedType,
  explodeValidators,
} from 'lane-shared/helpers/properties';
import Types from 'lane-shared/properties/Types';
import Color from 'lane-shared/properties/baseTypes/Color';
import {
  OPTION_NAME_MAXLENGTH,
  OPTION_VALUE_MAXLENGTH,
} from 'lane-shared/properties/baseTypes/Option';
import { ContentOptionsType } from 'lane-shared/renderers/v5/features/types/ContentInteractionRequirementType';
import securePropertyField, { // @ts-expect-error ts-migrate(2614) FIXME: Module '"lane-shared/renderers/v5/securePropertyFi... Remove this comment to see the full error message
  securePropertyFieldValueType,
} from 'lane-shared/renderers/v5/securePropertyField';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';

import CheckboxGroup from 'components/form/CheckboxGroup';
import ColorPickerButton from 'components/form/ColorPickerButton';
import CurrencyInput from 'components/form/CurrencyInput';
import DatePickerButton from 'components/form/DatePickers/DatePickerButton';
import DateRangePickerButton from 'components/form/DatePickers/DateRangePickerButton';
import DateTimeRangePicker from 'components/form/DatePickers/DateTimeRangePicker';
import TimePicker from 'components/form/DatePickers/TimePicker';
import Dropdown from 'components/form/Dropdown';
import Input from 'components/form/Input';
import MultiselectField from 'components/form/MultiselectField';
import PhoneNumberInput from 'components/form/PhoneNumberInput';
import QuantityInput from 'components/form/QuantityInput';
import RadioGroup from 'components/form/RadioGroup';
import RichText from 'components/form/RichText';
import Slider from 'components/form/Slider';
import SortSelector from 'components/form/SortSelector';
import TextArea from 'components/form/TextArea';
import Toggle from 'components/form/Toggle';
import { AttachmentContainer } from 'components/lane/AttachmentContainer';
import ChannelIntegrationSelector from 'components/lane/ChannelIntegrationSelector';
import ChannelSelectorButton from 'components/lane/ChannelSelectorButton';
import ContentSelectorButton from 'components/lane/ContentSelectorButton';
import DayInput from 'components/lane/DayInput';
import FileSelectorButton from 'components/lane/FileSelectorButton';
import GroupRoleSelector from 'components/lane/GroupRoleSelector';
import IconBatchVariableInfo from 'components/lane/IconBatchVariableInfo';
import MediaPickerButton from 'components/lane/MediaPickerButton';
import MetatagSelectorButton from 'components/lane/MetatagSelectorButton';
import SectionSelectorButton from 'components/lane/SectionSelectorButton';
import SecurityInput from 'components/lane/SecurityInput';
import SelectUser from 'components/lane/SelectUser';
import ThemePaletteColorSelectorButton from 'components/lane/ThemePaletteColorSelectorButton';
import TimeRangeInput from 'components/lane/TimeRangeInput';
import UserSelectorButton from 'components/lane/UserSelectorButton';
import Flex from 'components/layout/Flex';
import { Alert, Modal } from 'components/lds';
import AddressInput from 'components/typeInputs/AddressInput';
import DirectoryInput from 'components/typeInputs/DirectoryInput';
import FlexAlignItemsInput from 'components/typeInputs/FlexAlignItemsInput';
import FlexDirectionInput from 'components/typeInputs/FlexDirectionInput';
import FlexJustifyContentInput from 'components/typeInputs/FlexJustifyContentInput';
import FlexWrapInput from 'components/typeInputs/FlexWrapInput';
import GeoCoordinateInput from 'components/typeInputs/GeoCoordinateInput';
import GeoPolygonInput from 'components/typeInputs/GeoPolygonInput';
import IconProperty from 'components/typeInputs/IconProperty';
import LocationInput from 'components/typeInputs/LocationInput';
import TextAlignmentInput from 'components/typeInputs/TextAlignmentInput';

import PropertyValue from '../display/PropertyValue';

import styles from './PropertyInput.scss';

type Props = {
  disabled: any;
  timeZone: any;
  object: any;
  property: any;
  propertyKey: string;
  value: any;
  label?: string;
  description?: string;
  onChange: any;
  onPropertyChange?: ({ key, value }: { key: 'zoom'; value: any }) => void;
  channel: any;
  theme: any;
  library: any;
  keys: any;
};

export default function PropertyInput({
  disabled,
  timeZone,
  object,
  property,
  value,
  label,
  description,
  onChange,
  onPropertyChange = () => {},
  channel,
  theme,
  library,
  keys,
  propertyKey,
}: Props) {
  const [isAdminView] = useIsAdminView();
  const [isCreateForbidden, setIsCreateForbidden] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    value?.length ? value : []
  );
  const { content } = useContext(ContentRendererContext);
  const { t } = useTranslation();
  const isGenerator = Boolean(content?.generator);

  const errors = useContext(ValidationErrorContext);
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const error = errors?.inner?.find(error => error.path === propertyKey);
  const types = Types.getTypes();
  const packagedType = getPackagedType(property);
  const Label = label ? (
    <label className={styles.label}>{t(label)}</label>
  ) : null;

  let propertyColor: any;

  if (property.type === Color.name) {
    propertyColor = theme.palette.text;

    if (property.friendlyName.includes('Background Color')) {
      propertyColor = theme.palette.background;
    }
    if (property.default) {
      propertyColor = property.default;
    }
  }

  const { user } = useContext(UserDataContext);

  if (property.secure) {
    if (content && user) {
      const blockCRUD: securePropertyFieldValueType = securePropertyField({
        user,
        property,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentType' is not assignable to type 'Docu... Remove this comment to see the full error message
        content,
      });

      if (blockCRUD?.create) {
        onChange = () => {
          setIsCreateForbidden(true);
        };
      }
    }
  }

  const handleMultiSelectDropdownChanges = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions);
    onChange(selectedOptions);
  };

  const {
    maxValidator,
    minValidator,
    inValidator,
    arrayMaxValidator,
  } = explodeValidators(property.validators);

  if (property.editable === false) {
    return <PropertyValue channel={channel} field={property} value={value} />;
  }

  const onEmailChange = (value: string) => {
    const email = value.trim();
    onChange(email);
  };

  const placeholder = property.placeholder;

  // determine if some list items uses strings or options.  strings
  // are here for legacy support.
  const usesOptions =
    inValidator?.value && typeof inValidator?.value?.[0] !== 'string';
  // handle hinted UI/UX inputs first.

  function renderComponent() {
    switch (packagedType) {
      case PackagedTypeEnum.Dropdown: {
        return (
          <div data-cy={label}>
            {Label}
            <Dropdown
              disabled={disabled}
              className={styles.dropdown}
              placeholder={placeholder}
              onValueChange={value =>
                onChange(
                  usesOptions
                    ? (inValidator?.value as any[] | undefined)?.find(
                        option => option._id === value
                      )
                    : value
                )
              }
              value={usesOptions ? value?._id : value}
              items={(
                (inValidator?.value as any[] | undefined) || []
              )?.map(option =>
                usesOptions
                  ? { value: option._id, label: option.name }
                  : { value: option, label: option }
              )}
            />
          </div>
        );
      }
      case PackagedTypeEnum.Slider:
        return (
          <>
            {Label}
            <Slider
              className={styles.slider}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
              min={minValidator.value}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
              max={maxValidator.value}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
              value={value || minValidator.value}
              onChange={(value: any) => onChange(value)}
              disabled={disabled}
            />
          </>
        );
      case PackagedTypeEnum.Quantity:
        return (
          // @ts-expect-error ts-migrate(2741) FIXME: Property 'iconType' is missing in type '{ classNam... Remove this comment to see the full error message
          <QuantityInput
            className={styles.quantity}
            disabled={disabled}
            onChange={value => onChange(value)}
            quantity={value}
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            min={minValidator.value}
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            max={maxValidator.value}
          />
        );
      case PackagedTypeEnum.Radios:
        return (
          <div data-cy="radioSelection" className={styles.options}>
            <RadioGroup
              name={property.name}
              disabled={disabled}
              selected={usesOptions ? value?._id : value}
              schema={
                usesOptions
                  ? { id: '_id', text: 'name' }
                  : { id: 'value', text: 'label' }
              }
              items={
                (inValidator?.value as any[] | undefined)?.map(option =>
                  usesOptions ? option : { value: option, label: t(option) }
                ) ?? []
              }
              onChange={(value: any) =>
                onChange(
                  usesOptions
                    ? (inValidator?.value as any[] | undefined)?.find(
                        option => option._id === value
                      )
                    : value
                )
              }
              doTranslate={false}
            />
          </div>
        );
      case PackagedTypeEnum.Checkboxes:
        return (
          <div className={styles.options}>
            {Label}
            <CheckboxGroup
              name={property.name}
              disabled={disabled}
              selected={
                usesOptions ? value?.map((option: any) => option._id) : value
              }
              schema={
                usesOptions
                  ? { id: '_id', text: 'name' }
                  : { id: 'value', text: 'label' }
              }
              items={
                (inValidator?.value as any[] | undefined)?.map(option =>
                  usesOptions ? option : { value: option, label: t(option) }
                ) ?? []
              }
              onChange={values =>
                onChange(
                  usesOptions
                    ? values.map(value =>
                        (inValidator?.value as any[] | undefined)?.find(
                          option => option._id === value
                        )
                      )
                    : values
                )
              }
            />
          </div>
        );
      case PackagedTypeEnum.MultiSelect: {
        return (
          <div data-cy={label}>
            {Label}
            <MultiselectField
              items={(
                (inValidator?.value as any[] | undefined) || []
              )?.map(option =>
                usesOptions
                  ? { value: option._id, label: option.name }
                  : { value: option, label: option }
              )}
              onChange={handleMultiSelectDropdownChanges}
              value={selectedOptions}
              disabled={disabled}
              errors={error?.errors}
              truncateSelectedItems
            />
          </div>
        );
      }
      case PackagedTypeEnum.None:
      default:
        switch (property.type) {
          case types.Boolean!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <Toggle
                text={label}
                description={description}
                value={!!value}
                onChange={() => onChange(!value)}
                disabled={disabled}
                doTranslate
              />
            );
          case types.Em!.name: // TODO: fix typing of 'types' so these deterministically exist
          case types.Number!.name: // TODO: fix typing of 'types' so these deterministically exist
          case types.Percentage!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <Input
                className={styles.input}
                label={label}
                type="number"
                error={error?.errors}
                max={maxValidator && Number(maxValidator.value)}
                min={Number(minValidator?.value)}
                value={value}
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                onChange={value => onChange(types.Number.parseValue(value))}
                disabled={disabled}
              />
            );
          case types.Key!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <Dropdown
                  className={styles.dropdown}
                  placeholder={placeholder}
                  onValueChange={onChange}
                  disabled={disabled}
                  value={value}
                  items={keys}
                  dataCy={label}
                />
              </>
            );
          case types.PhoneNumber!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <PhoneNumberInput
                label={label}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
              />
            );

          case types.UUID!.name: // TODO: fix typing of 'types' so these deterministically exist
          /*
            Adding "Any" case and having it show string input instead of defaulting to null
            while the 'In' type is statically defined as Any. In the future if the In validator type is
            dynamically defined this can be removed.
          */
          case types.Any!.name: // eslint-disable-line no-fallthrough
          case types.String!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <Flex>
                <Input
                  fieldName={property.friendlyName}
                  className={styles.input}
                  label={label}
                  value={value}
                  dataCy={property.friendlyName}
                  type="text"
                  error={error?.errors}
                  placeholder={placeholder}
                  maxLength={maxValidator && Number(maxValidator.value)}
                  showLengthIndicator={isAdminView}
                  onChange={onChange}
                  disabled={disabled}
                  maxExpand
                />
                {isGenerator && <IconBatchVariableInfo placement="left" />}
              </Flex>
            );
          case types.Password!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <Flex>
                <Input
                  fieldName={property.friendlyName}
                  className={styles.input}
                  label={label}
                  value={value}
                  dataCy={label}
                  type="password"
                  error={error?.errors}
                  placeholder={placeholder}
                  maxLength={maxValidator && Number(maxValidator.value)}
                  showLengthIndicator={isAdminView}
                  onChange={onChange}
                  disabled={disabled}
                  maxExpand
                />
                {isGenerator && <IconBatchVariableInfo placement="left" />}
              </Flex>
            );
          case types.Email!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <Input
                className={styles.input}
                label={label}
                value={value}
                dataCy={label}
                type="text"
                error={error?.errors}
                placeholder={placeholder}
                maxLength={maxValidator && Number(maxValidator.value)}
                showLengthIndicator={isAdminView}
                onChange={value => onEmailChange(value)}
                disabled={disabled}
                maxExpand
              />
            );
          case types.Url!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <Input
                fieldName={property.friendlyName}
                className={styles.input}
                label={label}
                value={value}
                type="text"
                error={error?.errors}
                placeholder={placeholder}
                maxLength={maxValidator && Number(maxValidator.value)}
                onChange={onChange}
                disabled={disabled}
                maxExpand
              />
            );
          case types.Currency!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <CurrencyInput
                  placeholder={placeholder}
                  value={value}
                  data-cy={label}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ placeholder: any; value: any; "data-cy": s... Remove this comment to see the full error message
                  disabled={disabled}
                  max={maxValidator?.value}
                  min={minValidator?.value}
                  onValueChange={value => onChange(value)}
                />
              </>
            );
          case types.LocaleString!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <TextArea
                label={label}
                data-cy={label}
                placeholder={placeholder}
                value={value?.en || ''}
                errors={error?.errors}
                maxLength={maxValidator && Number(maxValidator.value)}
                showLengthIndicator
                onChange={text => onChange({ en: text })}
              />
            );
          case types.LongText!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <Flex>
                <TextArea
                  label={label}
                  data-cy={label}
                  className={styles.textArea}
                  containerClassName={styles.textAreaContainer}
                  disabled={disabled}
                  placeholder={placeholder}
                  value={value}
                  errors={error?.errors}
                  maxLength={maxValidator && Number(maxValidator.value)}
                  showLengthIndicator={isAdminView}
                  onChange={onChange}
                />
                {isGenerator && <IconBatchVariableInfo placement="left" />}
              </Flex>
            );
          case types.RichText!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <RichText
                  className={styles.richText}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                />
              </>
            );
          // TODO: fix typing of 'types' so these deterministically exist
          case types.Option!.name: {
            return (
              <div className={styles.option}>
                {Label}
                <fieldset>
                  <label>
                    {t(
                      'web.admin.channel.content.layout.editor.propertyInput.label'
                    )}
                  </label>
                  <Input
                    className={styles.input}
                    placeholder={placeholder}
                    value={value?.name}
                    maxLength={OPTION_NAME_MAXLENGTH}
                    showLengthIndicator={isAdminView}
                    type="text"
                    error={error?.errors}
                    onChange={name => onChange({ ...value, name })}
                    disabled={disabled}
                  />
                </fieldset>
                <fieldset>
                  <label>
                    {t(
                      'web.admin.channel.content.layout.editor.propertyInput.value'
                    )}
                  </label>
                  <Input
                    className={styles.input}
                    placeholder={placeholder}
                    type="text"
                    error={error?.errors}
                    maxLength={OPTION_VALUE_MAXLENGTH}
                    showLengthIndicator={isAdminView}
                    value={value?.value}
                    onChange={v => onChange({ ...value, value: v })}
                    disabled={disabled}
                  />
                </fieldset>
              </div>
            );
          }
          // TODO: fix typing of 'types' so these deterministically exist
          case types.Time!.name: {
            return (
              <>
                {Label}
                <TimePicker
                  disabled={disabled}
                  timeZone={timeZone}
                  className={styles.timePicker}
                  value={value}
                  useDates={false}
                  onChange={onChange}
                />
              </>
            );
          }
          // TODO: fix typing of 'types' so these deterministically exist
          case types.Color!.name: {
            if (theme) {
              return (
                <>
                  {Label}
                  <ThemePaletteColorSelectorButton
                    defaultValue={propertyColor}
                    value={value}
                    palette={theme.palette!}
                    onColorSelected={onChange}
                  />
                </>
              );
            }

            return (
              <>
                {Label}
                <ColorPickerButton
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ disabled: any; value: any; onChange: any; ... Remove this comment to see the full error message
                  disabled={disabled}
                  value={value}
                  onChange={onChange}
                  disableAlpha={false}
                />
              </>
            );
          }
          case types.DateTimeRange!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <DateTimeRangePicker
                  disabled={disabled}
                  timeZone={timeZone}
                  value={value}
                  onChange={onChange}
                />
              </>
            );
          case types.DateRange!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <DateRangePickerButton
                  disabled={disabled}
                  timeZone={timeZone}
                  startDate={value?.startDate}
                  endDate={value?.endDate}
                  onChange={onChange}
                  error={error?.errors}
                />
              </>
            );
          case types.DateTime!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <DatePickerButton
                  disabled={disabled}
                  timeZone={timeZone}
                  onSubmit={onChange}
                  value={value}
                  includeTime
                />
              </>
            );
          case types.Date!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}

                <DatePickerButton
                  disabled={disabled}
                  timeZone={timeZone}
                  onChange={onChange}
                  value={value}
                  includeTime={false}
                />
              </>
            );
          case types.Image!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <MediaPickerButton
                  disabled={disabled}
                  media={{ _id: value }}
                  storageKey={channel?._id}
                  onMediaSelected={media => onChange(media?._id)}
                  libraries={getLibraryOptions({ channel, library })}
                  errors={error?.errors}
                />
              </>
            );
          case types.Media!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <MediaPickerButton
                  disabled={disabled}
                  media={{ _id: value }}
                  storageKey={channel?._id}
                  onMediaSelected={media => onChange(media?._id)}
                  libraries={getLibraryOptions({ channel, library })}
                  errors={error?.errors}
                />
              </>
            );
          case types.Address!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <AddressInput value={value} onChange={onChange} />
              </>
            );
          case types.Location!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <LocationInput
                  disabled={disabled}
                  value={value}
                  zoom={object?.zoom}
                  onChange={onChange}
                  onZoomChanged={(zoom: any) =>
                    onPropertyChange({ key: 'zoom', value: zoom })
                  }
                />
              </>
            );
          case types.Icon!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}

                <IconProperty
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ disabled: any; value: any; theme: any; onC... Remove this comment to see the full error message
                  disabled={disabled}
                  value={value}
                  theme={theme}
                  onChange={onChange}
                />
              </>
            );
          case types.Channel!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <ChannelSelectorButton
                  disabled={disabled}
                  channelId={value?._id}
                  onChannelSelected={(channel: any) =>
                    onChange((channel && { _id: channel._id }) || null)
                  }
                />
              </>
            );
          case types.ChannelIntegration!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <ChannelIntegrationSelector
                  disabled={disabled}
                  channelIntegration={value}
                  onChannelIntegrationSelected={onChange}
                />
              </>
            );
          case types.Content!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <ContentSelectorButton
                  error={error?.errors}
                  disabled={disabled}
                  channelId={channel?._id}
                  contentId={value?._id}
                  options={property?.options as ContentOptionsType}
                  onContentSelected={(content: any) =>
                    onChange(
                      (content && { _id: content._id, type: content.type }) ||
                        null
                    )
                  }
                />
              </>
            );

          case types.Section!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <SectionSelectorButton
                  disabled={disabled}
                  channelId={channel?._id}
                  channelSlug={channel?.slug}
                  sectionId={value?._id}
                  onSectionSelected={(section: any) =>
                    onChange((section && { _id: section._id }) || null)
                  }
                />
              </>
            );
          case types.User!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}

                <UserSelectorButton
                  disabled={disabled}
                  channelId={channel?._id}
                  userId={value?._id}
                  onUserSelected={user =>
                    onChange((user && { _id: user._id }) || null)
                  }
                />
              </>
            );
          case types.GroupRole!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <GroupRoleSelector
                  error={error?.errors}
                  disabled={disabled}
                  channelId={channel?._id}
                  groupRoleId={value?._id}
                  onGroupRoleSelected={groupRole => {
                    onChange(groupRole ? { _id: groupRole._id } : null);
                  }}
                />
              </>
            );
          case types.Security!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}

                <SecurityInput
                  disabled={disabled}
                  channel={channel}
                  security={value}
                  onUpdated={props => onChange({ ...value, ...props })}
                />
              </>
            );
          case types.TextAlignment!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}

                <TextAlignmentInput
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                />
              </>
            );
          case types.FlexDirection!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}

                <FlexDirectionInput
                  onChange={onChange}
                  value={value}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onChange: any; value: any; disabled: any; ... Remove this comment to see the full error message
                  disabled={disabled}
                />
              </>
            );
          case types.FlexWrap!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}

                <FlexWrapInput
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                />
              </>
            );
          case types.FlexJustifyContent!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <FlexJustifyContentInput
                  onChange={onChange}
                  value={value}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onChange: any; value: any; disabled: any; ... Remove this comment to see the full error message
                  disabled={disabled}
                />
              </>
            );
          case types.FlexAlignItems!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <FlexAlignItemsInput
                  onChange={onChange}
                  value={value}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onChange: any; value: any; disabled: any; ... Remove this comment to see the full error message
                  disabled={disabled}
                />
              </>
            );
          case types.Directory!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}

                <DirectoryInput
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                />
              </>
            );
          case types.Day!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}

                <DayInput
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                  timeZone={timeZone}
                />
              </>
            );
          case types.TimeRange!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <TimeRangeInput
                  onChange={onChange}
                  value={value}
                  timeZone={timeZone}
                  useDates={false}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onChange: any; value: any; timeZone: any; ... Remove this comment to see the full error message
                  disabled={disabled}
                  unit={15}
                />
              </>
            );
          case types.JSON!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <FileSelectorButton
                onFileSelected={(file: any) => onChange(file)}
                type="json"
                accept="application/JSON"
                value={value}
                className={styles.fileSelector}
              />
            );
          case types.File!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <FileSelectorButton
                  onFileSelected={(file: any) => onChange(file)}
                  type="file"
                  accept="application/x-pkcs12"
                  value={value}
                  className={styles.fileSelector}
                />
              </>
            );
          // TODO: fix typing of 'types' so these deterministically exist
          case types.Sort!.name: {
            return (
              <>
                {Label}

                <SortSelector
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ disabled: any; value: any; onChange: any; ... Remove this comment to see the full error message
                  disabled={disabled}
                  value={value}
                  onChange={onChange}
                  disableAlpha={false}
                />
              </>
            );
          }
          case types.GeoPolygon!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <GeoPolygonInput value={value} onChange={onChange} />
              </>
            );
          case types.GeoCoordinate!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <GeoCoordinateInput
                  name={label}
                  value={value}
                  center={channel?.address?.geo}
                  onChange={onChange}
                />
              </>
            );
          case types.Metatag!.name: // TODO: fix typing of 'types' so these deterministically exist
            return (
              <>
                {Label}
                <MetatagSelectorButton
                  disabled={disabled}
                  channel={channel}
                  metatagId={value?._id}
                  onMetatagSelected={(metatag: any) =>
                    onChange((metatag && { _id: metatag._id }) || null)
                  }
                />
              </>
            );
          case types.SelectUser?.name: {
            let maxCapacity = 1;
            if (property.isMultiSelect && arrayMaxValidator?.value) {
              maxCapacity = arrayMaxValidator?.value;
            }
            return (
              <SelectUser
                disabled={disabled}
                buttonPlaceholder={property.placeholder}
                groupRoles={property.filters.groupRoles}
                onChange={onChange}
                maxCapacity={maxCapacity}
                value={value}
              />
            );
          }
          case types.Attachment?.name: {
            return (
              <AttachmentContainer
                onChange={onChange}
                entityId={value?.customGeneratedId}
                editMode
              />
            );
          }
          case types.Block!.name: // TODO: fix typing of 'types' so these deterministically exist
          case types.Link!.name: // TODO: fix typing of 'types' so these deterministically exist
          default:
            // todo: not implemented
            return null;
        }
    }
  }
  return (
    <>
      {property.secure ? (
        <>
          <Modal
            isOpen={isCreateForbidden}
            title={t('Forbidden')}
            onClose={() => setIsCreateForbidden(false)}
          >
            <Alert type="info">
              {t('This action is blocked by the admin')}
            </Alert>
          </Modal>
          {renderComponent()}
        </>
      ) : (
        renderComponent()
      )}
    </>
  );
}
