import { useLazyQuery } from '@apollo/client';

import { getContentQuantityClaimedByUser } from 'graphql-queries';

type QueryDataResult = {
  contentQuantityClaimedByUser: number;
};

type QueryVariables = {
  contentId: string;
};

export const useGetUserContentQuantityForContent = ({
  contentId,
}: {
  contentId: string;
}) => {
  const [fetchUserQuantityClaimedCount, { loading, data }] = useLazyQuery<
    QueryDataResult,
    QueryVariables
  >(getContentQuantityClaimedByUser, {
    variables: {
      contentId,
    },
  });

  const handleFetchUserQuantityClaimedCount = async () => {
    fetchUserQuantityClaimedCount();
  };

  return {
    userQuantityCount: data?.contentQuantityClaimedByUser || 0,
    loading,
    fetchUserQuantityClaimedCount: handleFetchUserQuantityClaimedCount,
  };
};
