import { useContext } from 'react';

import { AnalyticsContext } from '../../contexts';
import { ChannelType } from '../../types/ChannelType';
import { channelCollectables, Collectable } from './collectables';

export const useUnderConstructionAnalytics = () => {
  const { track } = useContext(AnalyticsContext);

  const trackViewed = (step: string, collectable: Collectable | null) => {
    track(`UnderConstruction.View.${step}`, { extraInformation: collectable });
  };

  const trackExited = (step: string, collectables: Collectable | null) => {
    track(`UnderConstruction.Exit.${step}`, {
      extraInformation: collectables,
    });
  };

  const convertChannelArrayIntoChannelCollectableObjects = (
    channelArray: ChannelType[]
  ) => {
    const channelsCollectableObject = channelArray.reduce(
      (
        acc: Record<ChannelType['_id'], ReturnType<typeof channelCollectables>>,
        channel
      ) => {
        if (!channel) return acc;
        acc[channel._id] = channelCollectables(channel);
        return acc;
      },
      {}
    );
    return channelsCollectableObject;
  };

  const underConstructionTracker = {
    View: {
      Index: ({ availableChannels }: { availableChannels: ChannelType[] }) => {
        trackViewed(
          'Index',
          convertChannelArrayIntoChannelCollectableObjects(availableChannels)
        );
      },
    },
    Exit: {
      SelectedChannel: ({
        availableChannels,
        selectedChannel,
      }: {
        availableChannels: ChannelType[];
        selectedChannel: ChannelType;
      }) => {
        trackExited('SelectedChannel', {
          availableChannels: convertChannelArrayIntoChannelCollectableObjects(
            availableChannels
          ),
          selectedChannel: channelCollectables(selectedChannel),
        });
      },
      Back: ({ availableChannels }: { availableChannels: ChannelType[] }) => {
        trackExited(
          'Back',
          convertChannelArrayIntoChannelCollectableObjects(availableChannels)
        );
      },
      Profile: ({
        availableChannels,
      }: {
        availableChannels: ChannelType[];
      }) => {
        trackExited(
          'Profile',
          convertChannelArrayIntoChannelCollectableObjects(availableChannels)
        );
      },
      Settings: ({
        availableChannels,
      }: {
        availableChannels: ChannelType[];
      }) => {
        trackExited(
          'Settings',
          convertChannelArrayIntoChannelCollectableObjects(availableChannels)
        );
      },
      ChannelSwitcher: ({
        availableChannels,
      }: {
        availableChannels: ChannelType[];
      }) => {
        trackExited(
          'Settings',
          convertChannelArrayIntoChannelCollectableObjects(availableChannels)
        );
      },
      FindAnotherWorkplace: () => {
        trackExited('FindAnotherWorkplace', null);
      },
    },
  };

  return { underConstructionTracker };
};
