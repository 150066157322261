import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { LibraryType } from 'lane-shared/types/libraries/LibraryType';
import {
  DocumentMediaOptions,
  ImageMediaOptions,
  MediaImageContentTypeEnum,
} from 'lane-shared/types/media';

import FileInput from '../form/FileInput';
import Button from '../general/Button';
import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import useMediaUpload from 'hooks/useMediaUpload';

import styles from './MediaLibraryAddMedia.scss';
import type { Media } from 'graphql-query-contracts';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  selectedLibrary: LibraryType;
  onMediaCreated: (media: Media) => void;
  path: string | null | undefined;
  tags: string[] | null | undefined;
  mediaOptions: ImageMediaOptions | DocumentMediaOptions;
  accept?: string;
  buttonText?: string;
  disabled?: boolean;
  type?: FileReturnTypeEnum;
  s3Bucket?: string;
};

export default function MediaLibraryAddMedia({
  className,
  style,
  selectedLibrary,
  onMediaCreated = () => null,
  mediaOptions,
  accept = Object.values(MediaImageContentTypeEnum).toString(),
  tags,
  path,
  buttonText,
  disabled = false,
  s3Bucket,
  type = FileReturnTypeEnum.Image, // setting a default to ensure current functionality doesnt change
}: Props) {
  const { t } = useTranslation();

  const { uploading, filesSelectedHandler } = useMediaUpload({
    selectedLibrary,
    onMediaCreated,
    mediaOptions,
    tags,
    path,
    s3Bucket,
  });

  return (
    <div className={cx(styles.MediaLibraryAddMedia, className)} style={style}>
      <FileInput
        disabled={uploading || disabled}
        loading={uploading}
        type={type}
        accept={accept}
        onFilesSelected={(files: any) => filesSelectedHandler(files)}
        enableMultiUpload
      >
        <Button
          loading={uploading}
          variant="contained"
          className={styles.button}
          dataCy="addMediaButton"
        >
          {buttonText || t('Add Image')}
        </Button>
      </FileInput>
    </div>
  );
}
