import React, { useState, useEffect, useContext } from 'react';

import { Icon } from 'design-system-web';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import RendererContext from 'lane-shared/contexts/RendererContext';
import { objectToArray, arrayReorder } from 'lane-shared/helpers';
import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { byOrder } from 'lane-shared/helpers/sort';
import { useChannelTheme } from 'lane-shared/hooks';
import Features from 'lane-shared/renderers/v5/features';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import { PropertyType } from 'lane-shared/types/properties/Property';
import { TypeContextEnum } from 'lane-shared/types/properties/TypeContextEnum';
import { PropertiesInterfaceDependencies } from 'lane-shared/types/properties/propertyInterfaceOptions/propertiesInterfaceDependencies';
import { PropertyDependency } from 'lane-shared/types/properties/propertyInterfaceOptions/propertyDependency';

import PropertyInput from 'components/builder/properties/input/PropertyInput';
import Label from 'components/general/Label';

import FieldButton from '../builder/properties/creation/FieldButton';
import FieldEditWindow from '../builder/properties/creation/FieldEditWindow';
import { findBlock, removeBlock } from './helpers';

import styles from './DataBuilder.scss';

type EditingField = {
  isNew: boolean;
  previousName?: string;
  field: PropertyType;
};

function DataBuilder({
  channel,
  user,
  content,
  showList = true,
  contexts = [TypeContextEnum.Data],
  onContentUpdated,
}: any) {
  const { blocks } = useContext(RendererContext);
  const theme = useChannelTheme(channel);
  const [definition, setDefinition] = useState<PropertyType[]>([]);
  const [editingField, setEditingField] = useState<EditingField | null>(null);
  const { t } = useTranslation();

  const propertiesInterfaceDependencies = PropertiesInterfaceDependencies.fromJsonData(
    content.data,
    content.propertiesOptions?.dependencies || []
  );

  useEffect(() => {
    // make this an array so its easier to use.
    const def = objectToArray(content.data, true);
    def.sort(byOrder);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ name?: string | undefined; }[]... Remove this comment to see the full error message
    setDefinition(def);
  }, [content.data]);

  function addNewField() {
    // find a unique key name for this new field.
    let i = Object.keys(definition).length;
    let name;

    while (true) {
      name = `field_${i++}`;
      if (!content.data[name]) {
        break;
      }
    }

    setEditingField({
      isNew: true,
      field: {
        _id: uuid(),
        placeholder: '',
        name,
        type: 'String',
        width: 150,
      },
    });
  }

  function editField(field: any) {
    setEditingField({
      isNew: false,
      previousName: field.name,
      field: { ...field },
    });
  }

  async function removeField(field: any) {
    try {
      await window.Alert.confirm({
        title: t('web.admin.content.editor.data.removeAlert.title', {
          name: field.friendlyName,
        }),
        message: t('web.admin.content.editor.data.removeAlert.message', {
          name: field.friendlyName,
        }),
      });
    } catch (err) {
      return;
    }

    propertiesInterfaceDependencies.removeDependency(field.name);

    // check the blocks for any blocks with this bruh.

    if (content.block) {
      for (const ancestorDependencyFieldRef of propertiesInterfaceDependencies.getAncestorFieldsRecursive(
        field.name
      )) {
        const block = findBlock({
          content,
          blocks,
          key: 'for',
          value: ancestorDependencyFieldRef,
        });

        if (block) {
          removeBlock(content, block, blocks);
        }
      }
    }

    delete content.data[field.name];
    setEditingField(null);
    onContentUpdated({
      block: { ...content.block },
      data: { ...content.data },
      propertiesOptions: {
        dependencies: propertiesInterfaceDependencies.serialize(),
      },
    });
  }

  function addField(field: any, propertyDependency?: PropertyDependency) {
    setEditingField(null);

    if (propertyDependency) {
      propertiesInterfaceDependencies.addDependency(
        propertyDependency.propertyRef,
        propertyDependency
      );
    }

    // set the definition on the content...
    content.data[field.name] = field;

    if (!editingField?.isNew) {
      // clear the existing example data.
      // we edited a field, remove corresponding appearance block to match.

      if (content.block) {
        for (const ancestorDependencyFieldRef of propertiesInterfaceDependencies.getAncestorFieldsRecursive(
          field.name
        )) {
          const block = findBlock({
            content,
            blocks,
            key: 'for',
            value: ancestorDependencyFieldRef,
          });

          if (block) {
            removeBlock(content, block, blocks);
          }
        }
      }
    }

    onContentUpdated({
      data: { ...content.data },
      propertiesOptions: {
        dependencies: propertiesInterfaceDependencies.serialize(),
      },
    });
  }

  function onPropertiesDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    setDefinition(
      arrayReorder(definition, result.source.index, result.destination.index)
    );

    onContentUpdated({
      data: { ...content.data },
      propertiesOptions: {
        dependencies: propertiesInterfaceDependencies.serialize(),
      },
    });
  }

  return (
    <div className={styles.DataBuilder} role="presentation">
      <div className={styles.container}>
        {showList && (
          <div className={styles.dataForm}>
            <Label h1>
              {t('web.admin.content.editor.data.title')}
              <Icon
                name="plus-circle"
                set={ICON_SET_FONTAWESOME}
                className={styles.addIcon}
                onClick={() => addNewField()}
                dataCy="addDataField"
              />
            </Label>
            <section>
              <DragDropContext onDragEnd={onPropertiesDragEnd}>
                <Droppable droppableId="properties">
                  {provided => (
                    <ul className={styles.properties} ref={provided.innerRef}>
                      {definition.map((property, index) => (
                        <Draggable
                          key={property.name}
                          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                          draggableId={property.name}
                          index={index}
                          isDragDisabled={false}
                        >
                          {(provided, snapshot) => (
                            <li
                              key={property.name}
                              ref={provided.innerRef}
                              data-is-dragging={snapshot.isDragging}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Icon
                                name="bars"
                                set={ICON_SET_FONTAWESOME}
                                className={styles.iconReorder}
                              />
                              <fieldset
                                className={styles.property}
                                key={property.name}
                              >
                                <label>
                                  {t(
                                    property.friendlyName || property.name || ''
                                  )}
                                </label>
                                <PropertyInput
                                  theme={theme}
                                  channel={channel}
                                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ theme: ThemeType; channel: any; geo: any; ... Remove this comment to see the full error message
                                  geo={channel?.address?.geo}
                                  property={property}
                                  onChange={() => null}
                                />
                              </fieldset>
                              {!property?.hideEditFromBuilder && (
                                <Icon
                                  name="pencil"
                                  type={FONT_AWESOME_REGULAR}
                                  set={ICON_SET_FONTAWESOME}
                                  className={styles.iconEdit}
                                  onClick={() => editField(property)}
                                  disabled={Boolean(
                                    property?.disableEditFromBuilder
                                  )}
                                  dataCy={`edit-${index}`}
                                />
                              )}

                              {!property?.hideDeleteFromBuilder && (
                                <Icon
                                  name="times-circle"
                                  set={ICON_SET_FONTAWESOME}
                                  className={styles.removeIcon}
                                  style={{ marginLeft: '1em' }}
                                  onClick={() => removeField(property)}
                                  disabled={Boolean(
                                    property?.disableDeleteFromBuilder
                                  )}
                                  dataCy={`delete-${index}`}
                                />
                              )}
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </section>

            {content.features?.map((feature: any) => {
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              const Feature = Features[feature.type];
              const properties = objectToArray(Feature.interactionData);

              if (properties.length === 0) {
                return null;
              }

              if (
                !channel?.settings.hasVisitorManagementEnabled &&
                feature.type === FeatureNameEnum.VisitorManagement
              ) {
                return null;
              } // hides the visitor management properties if toggled off by the user in channel settings

              // filter out any system read only properties?
              // filter out any properties the user can't update?
              return (
                <section key={feature.type}>
                  <Label h2>
                    {t('web.admin.content.editor.data.feature.label', {
                      name: t(Feature.friendlyName),
                    })}
                  </Label>
                  {properties.map(field => (
                    <FieldButton
                      key={field.name}
                      onClick={() => null}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name?: string | undefined; }' is not assig... Remove this comment to see the full error message
                      field={field}
                    />
                  ))}
                </section>
              );
            })}
          </div>
        )}
        {editingField && (
          <FieldEditWindow
            content={content}
            channel={channel}
            user={user}
            contexts={contexts}
            definition={definition}
            field={editingField.field}
            properties={content.data}
            propertyDependencies={propertiesInterfaceDependencies}
            propertyDependency={propertiesInterfaceDependencies?.dependencies?.get(
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
              editingField.field.name
            )}
            forCreate={editingField.isNew}
            onCancel={() => setEditingField(null)}
            onSave={(field, propertyDependency) =>
              addField(field, propertyDependency)
            }
            onDelete={field => removeField(field)}
            onContentUpdated={onContentUpdated}
          />
        )}
      </div>
    </div>
  );
}

export default DataBuilder;
