import { PackagedTypeEnum } from '../../../types/properties/PackagedTypeEnum';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import { PERMISSION_INTEGRATION_READ } from '../../constants/permissions';
import { IntegrationDefinition } from 'lane-shared/types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'lane-shared/types/integrations/IntegrationEnums';

function constructOIDCRedirectUrl({
  integrationId,
  thirdPartyRedirectUrl,
}: {
  integrationId: string;
  thirdPartyRedirectUrl: string | null;
}) {
  if (!thirdPartyRedirectUrl) return;

  const url = new URL(thirdPartyRedirectUrl);
  url.searchParams.set('ext-integration-id', integrationId);

  return url.toString();
}

const OIDCIntegrationDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.OIDC,
  allowsMultipleInstances: true,
  hasCustomUI: true,
  friendlyName: 'OIDC : Auth0 As Identity Provider',
  description:
    'Defines properties for a OIDC integration using Auth0 as the identity provider.',
  category: IntegrationCategoryEnum.Service,
  platforms: [],
  type: IntegrationTypeEnum.WebView,
  settings: {},
  getChannelIntegrationName(integrationData): string {
    return `OIDC - ${integrationData.settings.pageTitle}`;
  },
  properties: {
    pageTitle: {
      friendlyName: 'Page Title',
      type: 'String',
      description: 'The title of the page',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    description: {
      friendlyName: 'Page Description',
      type: 'LongText',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    serviceProvider: {
      friendlyName: 'Service Provider',
      packagedType: PackagedTypeEnum.Dropdown,
      type: 'Option',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    redirectURL: {
      friendlyName: 'Redirect URL',
      type: 'Url',
      description: 'The OIDC service provider redirect URL.',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    customClaims: {
      friendlyName: 'Custom claims',
      type: 'String',
      description: 'Custom claims fields required by current provider.',
      validators: [],
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    includeTenantId: {
      friendlyName: 'Tenant Id',
      type: 'Boolean',
      description: 'Include tenant id in customClaims?',
      validators: [],
      default: true,
    },
    includeTenantName: {
      friendlyName: 'Tenant Name',
      type: 'Boolean',
      description: 'Include tenant name in customClaims?',
      validators: [],
      default: true,
    },
  },
  mobilePermissions: [],
};

export { OIDCIntegrationDefinition, constructOIDCRedirectUrl };
