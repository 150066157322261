import React, { useState, useMemo, useLayoutEffect } from 'react';

import cx from 'classnames';
import { useScrollToSelectedChild } from 'design-system-web';
import { DateTime, Interval } from 'luxon';
import { Key } from 'ts-key-enum';

import { arrayClamp } from 'lane-shared/helpers';
import { LONG_TIME } from 'lane-shared/helpers/constants/dates';

import styles from './TimePicker.scss';

const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;

type Option = {
  label: string;
  value: string;
};

type OwnProps = {
  /** Should the time picker use date objects, or time strings? */
  useDates?: boolean;
  /** the current time selected today, in the number of minutes since the start of the day. */
  value?: string | Date | null | undefined;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onChange?: (any) => any;
  unit?: number;
  timeZone?: string | null;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  placeholder?: string;
  hideLabel?: boolean;
  label?: string;
};

type Props = OwnProps;

/**
 * @deprecated use TimePicker from design-system-web instead.
 */
export default function TimePicker({
  useDates = true,
  value = '',
  onChange = () => {},
  timeZone = null,
  unit = 15,
  className,
  style,
}: Props) {
  const options: Option[] = useMemo(() => {
    // create all the available units when the unit changes.
    // the times will just be strings instead of date times,
    // this saves a bit of effort dealing with time zones.
    const date = DateTime.fromJSDate(new Date()).startOf('day');
    const units = (HOURS_IN_DAY * MINUTES_IN_HOUR) / unit;

    // cycle through the unit of time to build out the options array.
    return new Array(units).fill(unit).map((u, i) => {
      const time = date.plus({ minutes: u * i });
      const label = time.toFormat(LONG_TIME);
      return {
        label,
        value: label,
      };
    });
  }, [unit]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  function isSelected(option: any, valueToCheck: any) {
    if (!valueToCheck) {
      return false;
    }

    // convert the option into a date object to do a check.
    const optionDate = DateTime.fromFormat(option.value, LONG_TIME);

    // if we are using date objects, convert to a time string and
    // back into a date based on the local time zone.  this saves a bunch
    // of timezone logic elsewhere.
    const valueDate = DateTime.fromFormat(
      useDates
        ? DateTime.fromJSDate(valueToCheck)
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            .setZone(timeZone)
            .toFormat(LONG_TIME)
        : valueToCheck,
      LONG_TIME
    );

    const interval = Interval.fromDateTimes(
      optionDate.minus({ minutes: unit / 2 }),
      optionDate.plus({ minutes: unit / 2 })
    );

    return interval.contains(valueDate);
  }

  function fireOnChange(newValue: any) {
    if (useDates) {
      const timeValue = DateTime.fromFormat(newValue, LONG_TIME);
      // we get the hours and minutes from the date picker, but we
      // get the actual date from the input value.  i.e. we maybe changing
      // to 2:30 PM, but the value that was passed in is on a specific date of
      // the year.

      const convertToJSDate =
        typeof value === 'string' ? DateTime.fromISO(value).toJSDate() : value;

      const referenceValue = DateTime.fromJSDate(
        convertToJSDate || new Date(),
        {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | Zo... Remove this comment to see the full error message
          zone: timeZone,
        }
      );

      const returnValue = referenceValue.set({
        hour: timeValue.hour,
        minute: timeValue.minute,
      });

      return onChange(returnValue.toJSDate());
    }

    // otherwise they are just expecting a time string.
    return onChange(newValue);
  }

  useLayoutEffect(() => {
    // find the selected time.
    setSelectedIndex(options.findIndex(option => isSelected(option, value)));
  }, [value, timeZone, options]);

  const [pickerRef] = useScrollToSelectedChild(selectedIndex);

  const renderedOptions = useMemo(
    () =>
      options.map((option, index) => (
        <div
          key={option.label}
          className={styles.option}
          role="button"
          tabIndex={0}
          onKeyPress={onKeyPress}
          onClick={() => fireOnChange(option.value)}
          data-is-selected={index === selectedIndex}
        >
          {option.label}
        </div>
      )),
    [options, selectedIndex, onChange]
  );

  function onKeyUp(e: any) {
    switch (e.key) {
      case Key.ArrowUp:
        fireOnChange(options[arrayClamp(selectedIndex - 1, options)].value);
        break;
      case Key.ArrowDown:
        fireOnChange(options[arrayClamp(selectedIndex + 1, options)].value);
        break;
      default:
        break;
    }
  }

  function onKeyPress(e: any) {
    if (e.key === Key.Enter) {
      fireOnChange(options[selectedIndex].value);
    }
  }

  return (
    <div
      data-cy="timePicker"
      ref={pickerRef}
      tabIndex={0}
      role="menu"
      onKeyUp={onKeyUp}
      className={cx(styles.TimePicker, className)}
      style={style}
    >
      {renderedOptions}
    </div>
  );
}
