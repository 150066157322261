import React, { useState, useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import { AnalyticsContext } from 'lane-shared/contexts';
import * as mutations from 'lane-shared/graphql/mutation';
import { getValidationMessages, pause } from 'lane-shared/helpers';
import { emitMemberInvitedToGroupRole } from 'lane-shared/helpers/analytics/emitGroupRole';
import { ChannelType, ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { validateCreateInvite } from 'lane-shared/validation';
import Input from '../form/Input';
import Button from '../general/Button';
import ErrorMessage from '../general/ErrorMessage';
import Modal from '../lds/Modal';
import { H5 } from '../typography';
import { ChannelSelectorModesEnum } from './ChannelSelector';
import ChannelSelectorButton from './ChannelSelectorButton';

import styles from './SendInviteModal.scss';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MutationSendInviteArgs } from 'graphql-resolver-contracts';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  groupRoleId: LaneType.UUID | null | undefined;
  groupRoleName: string | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  channel:
    | (Pick<ChannelType, '_id' | 'type' | 'isSub'> & {
        parent?: Pick<ChannelType, '_id' | 'type'> | undefined;
      })
    | undefined;
};

export default function SendInviteModal({
  className,
  style,
  isOpen,
  groupRoleId,
  groupRoleName,
  onClose,
  channel,
}: Props) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [companyId, setCompanyId] = useState<string>();
  const [validation, setValidation] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const analytics = useContext(AnalyticsContext);
  const { t } = useTranslation();

  function getCompanyId() {
    if (companyId) {
      return companyId;
    }

    // NOTE: unsure about this exact condition, it's based on the specifics of our current bug
    if (
      channel?.isSub === false &&
      channel.parent?.type === ChannelTypeEnum.Company
    ) {
      return channel.parent._id;
    }

    return undefined;
  }

  async function sendInvite() {
    setValidation(undefined);
    setError(undefined);

    const invite = {
      name,
      email,
      groupRoleId,
      companyId: getCompanyId(),
    } as MutationSendInviteArgs;

    invite.mobileAccess = false;
    invite.accessControlGroups = [];

    try {
      await validateCreateInvite.validate(invite, { abortEarly: false });
    } catch (err) {
      setValidation(err);
      return;
    }

    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: mutations.sendInvite,
        variables: invite,
      });
      emitMemberInvitedToGroupRole({
        groupRoleId: String(groupRoleId),
        groupRoleName: String(groupRoleName),
        analytics,
      });
      onClose();
      setName('');
      setEmail('');
      setCompanyId(undefined);
      window.Toast.show(
        <p>
          {t(
            'web.admin.channel.teamManagement.team.SendInviteModal.sentToast',
            { name: invite.name }
          )}
        </p>
      );
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  const modalTitle = groupRoleName
    ? t('web.admin.channel.teamManagement.team.SendInviteModal.titleWithName', {
        groupRoleName,
      })
    : t('web.admin.channel.teamManagement.team.SendInviteModal.title');

  return (
    <Modal
      className={cx(styles.SendInviteModal, className)}
      style={style}
      title={modalTitle}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} loading={loading} dataCy="sendInviteCancel">
            {t('web.admin.channel.teamManagement.team.SendInviteModal.cancel')}
          </Button>
          <Button
            dataCy="sendInvite"
            onClick={sendInvite}
            loading={loading}
            variant="contained"
          >
            {t('web.admin.channel.teamManagement.team.SendInviteModal.send')}
          </Button>
        </>
      }
    >
      <ErrorMessage error={error} />

      <Input
        className={styles.input}
        dataCy="inviteUserName"
        label="Name"
        error={getValidationMessages(validation, 'name')}
        value={name}
        placeholder="Name"
        onChange={name => setName(name)}
      />

      <Input
        className={styles.input}
        error={getValidationMessages(validation, 'email')}
        label="Email"
        dataCy="inviteUserEmail"
        value={email}
        placeholder="Email"
        onChange={email => setEmail(email)}
      />

      {channel?.type === ChannelTypeEnum.Property && (
        <>
          <H5 mb={2} mt={4}>
            {t(
              'web.admin.channel.teamManagement.team.SendInviteModal.tenantFieldLabel'
            )}
          </H5>
          <ChannelSelectorButton
            placeholder="Select a tenant"
            storageKey="SendInviteChannelSelectorButton"
            channelId={companyId}
            relatedToChannelId={channel?._id}
            modes={[ChannelSelectorModesEnum.Relationship]}
            types={[
              ChannelTypeEnum.Company,
              ChannelTypeEnum.Service,
              ChannelTypeEnum.Restaurant,
              ChannelTypeEnum.Retail,
              ChannelTypeEnum.Entertainment,
              ChannelTypeEnum.Professional,
              ChannelTypeEnum.Charity,
            ]}
            onChannelSelected={(channel: any) => setCompanyId(channel?._id)}
          />
        </>
      )}
    </Modal>
  );
}
