import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { useFlag } from 'lane-shared/hooks';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';
import {
  AmazonS3Buckets,
  DocumentMediaOptions,
  ImageMediaOptions,
  MediaImageContentTypeEnum,
} from 'lane-shared/types/media';

import FileInput from '../form/FileInput';
import Button from '../general/Button';
import { FileReturnType, FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import useMediaUpload from 'hooks/useMediaUpload';
import type { Media } from 'graphql-query-contracts';

import styles from './MediaLibraryAddMedia.scss';

type Props = {
  _id?: LaneType.UUID;
  className?: string;
  style?: React.CSSProperties;
  selectedLibrary: LibraryType;
  onMediaCreated: (media: DocumentType | Media) => void;
  path?: string | null;
  tags?: string[] | null;
  mediaOptions?: ImageMediaOptions | DocumentMediaOptions;
  accept?: string;
  buttonText?: string;
  disabled?: boolean;
  type?: FileReturnTypeEnum;
};

export function DocumentLibraryReplaceFile({
  _id,
  className,
  style,
  selectedLibrary,
  onMediaCreated = () => null,
  mediaOptions,
  accept = Object.values(MediaImageContentTypeEnum).toString(),
  disabled = false,
  type = FileReturnTypeEnum.Image, // setting a default to ensure current functionality doesnt change
}: Props) {
  const { t } = useTranslation();

  const saveToPrivateS3Bucket = useFlag(FeatureFlag.PrivateS3Media, false);
  const s3Bucket = saveToPrivateS3Bucket
    ? AmazonS3Buckets.Activate
    : AmazonS3Buckets.Lane;

  const { uploading, filesSelectedHandler } = useMediaUpload({
    selectedLibrary,
    onMediaCreated,
    mediaOptions,
    s3Bucket,
  });

  return (
    <div className={cx(styles.MediaLibraryAddMedia, className)} style={style}>
      <FileInput
        disabled={uploading || disabled}
        loading={uploading}
        type={type}
        accept={accept}
        onFileSelected={(file: FileReturnType, _name: string) => {
          file._id = _id;
          filesSelectedHandler([file]);
        }}
      >
        <Button
          loading={uploading}
          variant="outlined"
          className={styles.button}
          dataCy="addMediaButton"
          startIcon={<Icon name="repeat" />}
        >
          {t('web.media.mediaEdit.replace')}
        </Button>
      </FileInput>
    </div>
  );
}
