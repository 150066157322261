import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';
import * as yup from 'yup';

import { RendererContext, ValidationErrorContext } from 'lane-shared/contexts';
import { objectToArray } from 'lane-shared/helpers';
import {
  EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED,
  EVENT_CONTENT_INTERACTION_STATUSCHANGE,
} from 'lane-shared/helpers/constants/events';
import { INTERACTION_CANCELLED } from 'lane-shared/helpers/constants/interactions';
import { constructFeature } from 'lane-shared/helpers/content';
import getFeatureValidationErrorMapByContent from 'lane-shared/helpers/getFeatureValidationErrorMapByContent';
import hasPermission from 'lane-shared/helpers/hasPermission';
import useFlag from 'lane-shared/hooks/useFlag';
import useMerchantAccountStatus, {
  MerchantAccountStatus,
} from 'lane-shared/hooks/useMerchantAccountStatus';
import Features from 'lane-shared/renderers/v5/features';
import {
  FeatureDefinition,
  ContentTypeRequirement,
  ContentTypeRequirementEnum,
} from 'lane-shared/types/FeatureDefinition';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { FeatureInstance } from 'lane-shared/types/FeatureInstance';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import {
  SurveysFeatureProperties,
  SurveyTypeEnum,
} from 'lane-shared/types/features/SurveysFeatureProperties';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import { featureDisplayForContentType } from 'lane-web/src/components/renderers/helpers/contentFeatureDisplay';
import {
  getSurveyContentToAdd,
  getSurveyContentToRemove,
} from 'lane-web/src/domains/surveys/helpers';

import { getSurveyContentOnFeatureUpdate } from '../../../domains/surveys/helpers/getSurveyContentOnFeatureUpdate';
import { useQueryString } from '../../../hooks';
import useCurrentChannelIntegrations from '../../../hooks/useCurrentChannelIntegrations';
import { findBlock, removeBlock } from '../helpers';
import FeatureMenuItem from './FeatureMenuItem';
import FeatureRenderers from './FeatureRenderers';

import styles from './FeatureBuilder.scss';
import { useTranslation } from 'react-i18next';
import { useFeatureExclusivity } from 'hooks/useFeatureExclusivity';
import { useQuantityWaitlistEnabled } from 'lane-shared/hooks/useQuantityWaitlistEnabled';
import { useQuantityAdvancedRulesEnabled } from 'lane-shared/hooks/useQuantityAdvancedRulesEnabled';

export default function FeatureBuilder({
  user,
  content,
  channel,
  timeZone,
  onContentUpdated,
  workOrderVariant,
}: any) {
  const { t } = useTranslation();
  const { blocks } = useContext(RendererContext);
  const [query, goToUrl] = useQueryString<{
    feature: `${FeatureNameEnum}` | undefined;
  }>({
    feature: '',
  });
  const [validationErrorMap, setValidationErrorMap] = useState<
    Map<string, yup.ValidationError | null>
  >(new Map<string, yup.ValidationError | null>());

  const [
    activeContentFeature,
    setActiveContentFeature,
  ] = useState<FeatureInstance | null>(null);

  const emailVerificationRequiredFieldRequirement = useFlag(
    FeatureFlag.EmailVerificationRequirement,
    false
  );

  const requirementFeatureNonInteractive = useFlag(
    FeatureFlag.NonInteractiveRequirement,
    false
  );

  const visitorManagementEnabled = useFlag(
    FeatureFlag.VisitorManagement,
    false
  );

  const surveysEnabled = useFlag(FeatureFlag.Surveys, false);
  const isQuantityWaitlistEnabled = useQuantityWaitlistEnabled();
  const isQuantityAdvancedRulesEnabled = useQuantityAdvancedRulesEnabled();
  const [validateFeatureExclusivity, getFeatureRule] = useFeatureExclusivity();

  // if feature flag for email verification required field is turned on, let's ensure we're displaying verifiedEmailRequired property
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  Features[
    FeatureNameEnum.Requirements
  ].properties.verifiedEmailRequired.showControl = emailVerificationRequiredFieldRequirement;

  // Defining if feature is available for non interactive content based on feature flags
  Features[
    FeatureNameEnum.Requirements
  ].requiresInteraction = !requirementFeatureNonInteractive;

  const featuresArray = objectToArray<FeatureDefinition>(Features);

  const features = featuresArray.filter(feature => {
    let show = true;

    if (!user.isSuperUser && feature.superUserOnly) {
      show = false;
    }

    if (feature.requiresInteraction && !content.isInteractive) {
      show = false;
    }

    if (
      feature.permission &&
      !user.isSuperUser &&
      !hasPermission(user.roles, [feature.permission], channel?._id)
    ) {
      show = false;
    }

    if (
      feature.name === FeatureNameEnum.VisitorManagement &&
      (!visitorManagementEnabled ||
        !channel?.settings?.hasVisitorManagementEnabled)
    ) {
      show = false;
    }

    if (
      feature.name === FeatureNameEnum.Surveys &&
      (!surveysEnabled || !channel?.settings?.hasSurveysEnabled)
    ) {
      show = false;
    }

    return featureDisplayForContentType(feature, show, content.type);
  });

  const currentChannelIntegrations = useCurrentChannelIntegrations();

  const hasEssensys = useMemo(() => {
    return Boolean(
      currentChannelIntegrations
        .map(channelIntegration => channelIntegration.integration)
        .filter(
          integrations =>
            integrations?.name === IntegrationProviderEnum.Essensys
        )?.length
    );
  }, [currentChannelIntegrations]);

  const merchantAccountStatus: MerchantAccountStatus = useMerchantAccountStatus(
    {
      channelId: channel?._id,
    }
  );

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'FeatureDefinition | null | undefined' is not... Remove this comment to see the full error message
  const currentFeature: FeatureDefinition | null = useMemo(() => {
    if (!activeContentFeature) {
      return null;
    }
    return features.find(feature => feature.name === activeContentFeature.type);
  }, [activeContentFeature]);

  useEffect(() => {
    // TODO: When feature comes into focus re-run validation
    if (query.feature) {
      setActiveContentFeature(
        content?.features?.find(({ type }: any) => type === query.feature)
      );
    } else {
      setActiveContentFeature(null);
    }
  }, [query.feature, content?.features]);

  function contentUpdated() {
    if (activeContentFeature && content) {
      content.features[
        content.features.findIndex(
          (cf: any) => cf.type === activeContentFeature.type
        )
      ] = activeContentFeature;

      onContentUpdated({
        features: [...content.features],
      });
    }
  }

  const debounceContentUpdated = useDebouncedCallback(() => {
    contentUpdated();
  }, 500).callback;

  // hasn't been set in state yet, wait until it is
  if (!content) {
    return null;
  }

  function paymentFeatureErrorModal() {
    const message = `Oops! In order to use the payment feature this channel must have a corresponding Stripe account connected to accept the funds. Please reach out to your Customer Success Manager, or support@activate.vts.com to connect your account.`;
    window.Alert.alert({
      title: `Oops!`,
      message,
    });
  }

  function goToFeature(featureName: string) {
    // save before switching.
    contentUpdated();
    setActiveContentFeature(null);
    goToUrl({ feature: featureName });
  }

  function addFeature(feature: any) {
    if (
      feature.name === FeatureNameEnum.Payment &&
      !hasEssensys &&
      !merchantAccountStatus?.configured
    ) {
      paymentFeatureErrorModal();
      return;
    }

    if (isQuantityWaitlistEnabled) {
      const [hasIncompatibleFeature, errorsObject] = validateFeatureExclusivity(
        content,
        feature.name
      );

      if (hasIncompatibleFeature && errorsObject) {
        window.Alert.alert({
          title: t(errorsObject.errorTitle),
          message: t(errorsObject.errorContent),
        });
        return;
      }
    }

    if (isQuantityWaitlistEnabled || isQuantityAdvancedRulesEnabled) {
      const validateFeatureRules = getFeatureRule(feature.name);
      const [isValid, errors] = validateFeatureRules(content);
      if (!isValid && errors) {
        window.Alert.alert({
          title: t(errors.errorTitle),
          message: t(errors.errorContent),
        });
        return;
      }
    }

    // generate feature data from the definition.
    const featureInstance = constructFeature({
      featureDefinition: feature,
      location: content.geo,
      userId: user._id,
    });

    if (feature.name === FeatureNameEnum.Surveys) {
      const surveyType = (((featureInstance.feature as unknown) as FeatureInstance<SurveysFeatureProperties>)
        .type as unknown) as SurveyTypeEnum;
      const surveyContent = getSurveyContentToAdd({
        content,
        surveyType,
        blocks,
      });

      // Add survey related questions to content.data and content.block
      onContentUpdated({
        ...surveyContent,
        features: [...(content.features || []), featureInstance],
      });
    } else {
      onContentUpdated({
        features: [...(content.features || []), featureInstance],
      });
    }

    setActiveContentFeature(featureInstance);
  }

  async function pullFeature(feature: any) {
    const removeWorkflows = [
      Features.Statuses.name,
      Features.Cancelable.name,
    ].includes(feature.name);

    try {
      let message = `Are you sure you want to remove ${feature.name}?`;

      if (removeWorkflows) {
        message += '\nThis will also remove any associated workflows.';
      }

      await window.Alert.confirm({
        title: `Remove ${feature.name}`,
        message,
      });
    } catch (err) {
      // user cancelled
      return;
    }

    let update = {
      features: content.features?.filter((cf: any) => cf.type !== feature.name),
    };

    if (feature.interactionData) {
      // Remove blocks that were added by this feature
      Object.keys(feature.interactionData).forEach(interactionName => {
        const block = findBlock({
          content,
          blocks,
          key: 'for',
          value: `${feature.name}.${interactionName}`,
        });
        if (block) {
          removeBlock(content, block, blocks);
        }
      });
    }

    if (removeWorkflows) {
      // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
      (update as any).actions = content.actions?.filter((action: any) => {
        if (feature.name === Features.Cancelable.name) {
          // if we are removing Cancelable, remove all workflows that
          // reference Cancelled status.
          return !(
            [action.workflow?.statusFrom, action.workflow?.statusTo].includes(
              INTERACTION_CANCELLED
            ) || action.event === EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED
          );
        }
        if (feature.name === Features.Statuses.name) {
          // if we are removing Statuses, remove all workflows that
          // reference any status change.
          return action.event !== EVENT_CONTENT_INTERACTION_STATUSCHANGE;
        }
      });
    }

    if (feature.name === FeatureNameEnum.Surveys) {
      const contentWithoutSurvey = getSurveyContentToRemove({
        content: { ...content, ...update },
      });

      (update as any) = contentWithoutSurvey;
    }

    onContentUpdated(update);

    setActiveContentFeature(null);
  }

  async function toggleFeature(isActive: boolean, feature: FeatureDefinition) {
    if (isActive) {
      await pullFeature(feature);
    } else {
      addFeature(feature);
    }
  }

  function onFeatureUpdated(props: any) {
    if (!currentFeature || !activeContentFeature) {
      return;
    }

    const nextFeatureState = {
      ...activeContentFeature.feature,
      ...props,
    };

    const nextValidationErrorMap = getFeatureValidationErrorMapByContent(
      content,
      {
        nextFeatureState,
        feature: currentFeature,
      }
    );
    setValidationErrorMap(nextValidationErrorMap);

    setActiveContentFeature({
      ...activeContentFeature,
      feature: nextFeatureState,
    });

    if (activeContentFeature.type === FeatureNameEnum.Surveys && props?.type) {
      const surveyContent = getSurveyContentOnFeatureUpdate({
        content,
        updatedFeature: nextFeatureState,
        blocks,
      });
      onContentUpdated(surveyContent);
    }
    debounceContentUpdated();
  }

  const activeFeature = Object.values(Features).find(
    f => f.name === query.feature
  );

  const FeatureRenderer = query.feature
    ? FeatureRenderers[query.feature]
    : undefined;

  const validationErrorContextValue: yup.ValidationError | null =
    currentFeature && validationErrorMap.has(currentFeature.name)
      ? validationErrorMap.get(currentFeature.name)!
      : null;

  return (
    <div className={styles.FeatureBuilder} data-cy="featureList">
      <menu className={styles.features}>
        {features.map(feature => {
          // POD-879: Hide Inventory feature until its completed
          // TM-1141: Hide Delivery, RemoteFetch, Scheduled, and Shipping features until they are completed
          if (
            feature.name === FeatureNameEnum.Inventory ||
            feature.name === FeatureNameEnum.Deliverable ||
            feature.name === FeatureNameEnum.RemoteFetch ||
            feature.name === FeatureNameEnum.Scheduled ||
            feature.name === FeatureNameEnum.Shipping
          ) {
            return null;
          }
          const isActive = content.features?.some(
            (cf: any) => cf.type === feature.name
          );
          if (!isActive && workOrderVariant) {
            return null;
          }
          const hasError =
            validationErrorMap.has(feature.name) &&
            validationErrorMap.get(feature.name) !== null;

          const disableToggle = disableFeatureToggle(
            content.type,
            feature.contentTypeRequirements
          );

          return (
            <FeatureMenuItem
              className={styles.featureMenuItem}
              key={feature.name}
              feature={feature}
              isActive={isActive}
              hasError={hasError}
              isSelected={query.feature === feature.name}
              onFeatureSelected={goToFeature}
              onFeatureEnabled={() => toggleFeature(isActive, feature)}
              disableToggle={workOrderVariant || disableToggle}
            />
          );
        })}
      </menu>

      {FeatureRenderer && activeContentFeature && activeFeature ? (
        <section className={styles.featureProperties}>
          <ValidationErrorContext.Provider value={validationErrorContextValue}>
            <FeatureRenderer
              className={styles.feature}
              timeZone={timeZone}
              content={content}
              channel={channel}
              featureName={activeFeature?.name}
              contentFeature={activeContentFeature}
              feature={activeFeature}
              onFeatureUpdated={onFeatureUpdated}
              workOrderVariant={workOrderVariant}
            />
          </ValidationErrorContext.Provider>
        </section>
      ) : null}
    </div>
  );
}

function disableFeatureToggle(
  contentType: ContentTypeEnum,
  featureRequirement?: ContentTypeRequirement[]
) {
  if (!featureRequirement) {
    return false;
  }

  const contentRequirement = featureRequirement.find(
    requirement => requirement.type === contentType
  );

  return (
    !!contentRequirement &&
    contentRequirement.requirement !== ContentTypeRequirementEnum.Optional
  );
}
