import React, { useState, useContext } from 'react';

import cx from 'classnames';
import { CircleListView } from 'components';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { colors } from 'lane-shared/config/colors';
import { assignAccessManager } from 'lane-shared/graphql/accessControl';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { ChannelsContext } from 'lane-shared/contexts';

import CardContainer from 'components/cards/CardContainer';
import { Modal } from 'components/lds';
import { H5 } from 'components/typography';

import { UserInfo } from '../../../types/AccessControl';
import { ErrorModal } from '../ErrorModal';
import { Button, S, Icon } from 'design-system-web';

import styles from './styles.scss';
import Radio from '../../../../../components/form/Radio';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

type Props = {
  channelId: string;
  user: UserInfo;
  isOpen: boolean;
  onClose: (triggerRefresh?: boolean) => void;
};

enum RadioOptions {
  company = 'company',
  property = 'property',
  deselected = 'deselected',
}

export function AssignAccessManagerModal({
  channelId,
  user,
  isOpen,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const { channels } = useContext(ChannelsContext);

  const channel = channels.find((channel: any) => channel._id === channelId);

  const [selectedChannelIds, setSelectedChannelIds] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>(
    RadioOptions.deselected
  );
  const [hasError, setHasError] = useState<boolean>(false);

  const onRoleSelect = (value: string) => {
    if (value === selectedRole) {
      setSelectedRole(RadioOptions.deselected);
    } else {
      setSelectedRole(value);
    }
    setSelectedChannelIds([]);
  };

  const onSelectCompanyChange = (value: string) => {
    if (selectedChannelIds.includes(value)) {
      setSelectedChannelIds(selectedChannelIds.filter(id => id !== value));
    } else {
      setSelectedRole(RadioOptions.company);
      setSelectedChannelIds([...selectedChannelIds, value]);
    }
  };

  const handleAssignAccessManagerModalClose = () => {
    onClose();
  };

  const getGroupRoleText = () => {
    if (selectedRole === RadioOptions.company) {
      return t(
        'web.admin.accessControl.userAccess.modal.assignAccessManager.role.companyAdmin'
      );
    }

    return t(
      'web.admin.accessControl.userAccess.modal.assignAccessManager.role.accessAdmin'
    );
  };

  const handleSaveChanges = async () => {
    if (!channel) {
      setHasError(true);
      return;
    }

    try {
      await getClient().mutate({
        mutation: assignAccessManager,
        variables: {
          channelId: channel._id,
          userId: user._id,
          isCompanyAdmin: selectedRole === RadioOptions.company,
          companyChannelIds: selectedChannelIds,
        },
      });
      onClose(true);
      window.Toast.show(
        t(
          'web.admin.accessControl.userAccess.modal.assignAccessManager.success',
          {
            userName: user?.name,
            groupRoleName: getGroupRoleText(),
          }
        )
      );
    } catch (err) {
      setHasError(true);
      console.error(err);
    }
  };

  if (hasError) {
    return (
      <ErrorModal
        title={t(
          'web.admin.accessControl.userAccess.modal.assignAccessManager.error.title'
        )}
        description={t(
          'web.admin.accessControl.userAccess.modal.assignAccessManager.error.description',
          {
            userName: user.name,
            groupRoleName: getGroupRoleText(),
          }
        )}
        buttonTitle={t('Retry')}
        iconName="x-circle"
        iconColor={colors.errorDark}
        isOpen={hasError}
        onClose={onClose}
        onClick={handleSaveChanges}
      />
    );
  }

  if (channel?.type === ChannelTypeEnum.Property) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={handleAssignAccessManagerModalClose}
        title={t(
          'web.admin.accessControl.userAccess.modal.assignAccessManager.title'
        )}
        className={cx(styles.modal)}
        size="large"
        dataCy="assignAccessManagerModal"
      >
        <div
          data-testid="access-control-modal"
          className={cx(styles.assignAccessManagerModalContent)}
        >
          <CardContainer className={cx(styles.profileContainer)}>
            <CircleListView
              style={{ padding: '0px' }}
              image={imageUrl(user.image)}
              logo={imageUrl(user.logo)}
              name={user.name}
            >
              <H5>{user.name}</H5>
              <S variant="secondary">
                {user.companies.length > 1 &&
                  `${t(
                    'web.admin.accessControl.userAccess.modal.assignAccessManager.multipleCompanies'
                  )} | `}
                {user.companies.length === 1 && `${user.companies[0].name} | `}
                {user.email}
              </S>
            </CircleListView>
          </CardContainer>
          <S>
            {t(
              'web.admin.accessControl.userAccess.modal.assignAccessManager.radioPromptText'
            )}
          </S>
          <div className={cx(styles.assignAccessManagerModalRadioButtons)}>
            <div
              className={cx(
                styles.assignAccessManagerModalRadioButtonContainer,
                selectedRole === RadioOptions.property
                  ? styles.assignAccessManagerModalRadioButtonContainerSelected
                  : ''
              )}
            >
              <Radio
                selected={selectedRole}
                value={RadioOptions.property}
                name={RadioOptions.property}
                onChange={v => onRoleSelect(v)}
                text="web.admin.accessControl.userAccess.modal.assignAccessManager.property"
                subtext={t(
                  'web.admin.accessControl.userAccess.modal.assignAccessManager.property.description',
                  { property: channel.profile?.name }
                )}
                className={cx(styles.assignAccessManagerRadioButtons)}
              />
            </div>
            {user.companies.length ? (
              <div
                className={cx(
                  styles.assignAccessManagerModalRadioButtonContainer,
                  selectedRole === RadioOptions.company
                    ? styles.assignAccessManagerModalRadioButtonContainerSelected
                    : ''
                )}
              >
                <Radio
                  selected={selectedRole}
                  value={RadioOptions.company}
                  name={RadioOptions.company}
                  onChange={v => onRoleSelect(v)}
                  text="web.admin.accessControl.userAccess.modal.assignAccessManager.company"
                  subtext="web.admin.accessControl.userAccess.modal.assignAccessManager.company.description"
                  className={cx(styles.assignAccessManagerRadioButtons)}
                />
                {user.companies.map(channel => (
                  <label key={channel.id} className={styles.checkboxWrapper}>
                    <div className={styles.checkboxContainer}>
                      <input
                        type="checkbox"
                        className={styles.checkboxInput}
                        checked={selectedChannelIds.includes(channel.id)}
                        onChange={() => onSelectCompanyChange(channel.id)}
                        data-testid={channel.id}
                      />
                      {selectedChannelIds.includes(channel.id) && (
                        <Icon
                          name="check"
                          className={styles.checkboxIcon}
                          set={ICON_SET_FONTAWESOME}
                          type="far"
                          testID="icon"
                        />
                      )}
                      {channel.name}
                    </div>
                  </label>
                ))}
              </div>
            ) : null}
          </div>
          <div className={cx(styles.assignAccessManagerModalButtons)}>
            <Button
              disabled={
                selectedRole === RadioOptions.deselected ||
                (selectedRole === RadioOptions.company &&
                  selectedChannelIds.length === 0)
              }
              dataCy="assignAccessManagersSaveChangesButton"
              size="large"
              onClick={handleSaveChanges}
            >
              {t(
                'web.admin.accessControl.userAccess.modal.assignAccessManager.save'
              )}
            </Button>
            <Button
              variant="secondary"
              dataCy="assignAccessManagersCancelButton"
              onClick={handleAssignAccessManagerModalClose}
              size="large"
            >
              {t('Cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  handleSaveChanges();
  return null;
}
