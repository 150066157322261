import React, { useRef, useMemo } from 'react';

import cx from 'classnames';

import TableHeader from './TableHeader';
import TableRow from './TableRow';
import { sortTableRows } from './helpers';
import { Column, Row, UrlSearchParams, Dir } from './types';
import useQueryString from 'hooks/useQueryString';

import styles from './styles.scss';

export type Props = {
  tableClassName?: string;
  tableStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  containerClassName?: string;
  /** Defines the number of columns and the table headers */
  columns: Column[];
  /** The table data */
  readonly rows: Row[];
  dataCy?: string;
  sortingIsDoneOn?: 'client' | 'server';
  /** If true, a column header with header "Actions" will be added. Add the actions to the rows prop */
  withActions?: boolean;
};

type PrivateProps = (Props) & {
  selectedRows?: Map<string, Row>;
  /** Callback function is called when a row is selected. */
  onRowSelect?: (rows: Map<string, Row>) => void;
};

/**
 * A table component that can be used to display data in a table.
 * By default, the table will sort the rows client-side. If you want to do server-side sorting,
 * use the PaginatedTable component instead (./PaginatedTable.tsx).
 */
/**
 * @deprecated This component is deprecated - use `Table` exported from `design-system-web` instead.
 */
export default function Table({
  tableStyle,
  tableClassName,
  containerStyle,
  containerClassName,
  rows = [],
  columns = [],
  onRowSelect,
  selectedRows = new Map(),
  dataCy,
  sortingIsDoneOn = 'client',
  withActions = false,
}: PrivateProps) {
  const rowsAreSelectable = useRef(Boolean(onRowSelect && selectedRows))
    .current;
  const [
    urlSearchParams,
    setUrlSearchParams,
  ] = useQueryString<UrlSearchParams>();

  const allRowsSelected = useMemo<boolean>(
    () =>
      selectedRows
        ? selectedRows.size > 0 && rows.every(row => selectedRows.has(row.id))
        : false,
    [selectedRows, rows]
  );

  /* Select all rows */
  function onHeaderCheckboxSelect() {
    if (onRowSelect && selectedRows) {
      if (allRowsSelected) {
        rows.forEach(row => selectedRows.delete(row.id));
      } else {
        rows.forEach(row => selectedRows.set(row.id, row));
      }
      onRowSelect(new Map(selectedRows));
    }
  }

  /* Select one rows */
  function onRowCheckBoxSelect(row: Row) {
    return () => {
      if (onRowSelect && selectedRows) {
        const _ = selectedRows.has(row.id)
          ? selectedRows.delete(row.id)
          : selectedRows.set(row.id, row);

        onRowSelect(new Map(selectedRows));
      }
    };
  }

  function handleRowSort(key: string) {
    return () => {
      const { sortKey, dir } = urlSearchParams;

      if (sortKey === key && dir === Dir.asc) {
        setUrlSearchParams({ sortKey: key, dir: Dir.desc });
        return;
      }

      if (sortKey === key && dir === Dir.desc) {
        setUrlSearchParams({ sortKey: null, dir: null });
        return;
      }

      if (sortKey !== key) {
        setUrlSearchParams({ sortKey: key, dir: Dir.asc });
      }
    };
  }

  const renderedRows = useMemo(() => {
    return rows
      .slice()
      .sort((a, b) =>
        sortTableRows(a, b, {
          urlSearchParams,
          disabled: sortingIsDoneOn === 'server',
        })
      )
      .map(row => {
        const isSelected = selectedRows ? selectedRows.has(row.id) : false;
        return (
          <TableRow
            key={row.id}
            row={row}
            columns={columns}
            rowsAreSelectable={rowsAreSelectable}
            onRowCheckBoxSelect={onRowCheckBoxSelect(row)}
            isSelected={isSelected}
          />
        );
      });
  }, [rows, selectedRows, urlSearchParams]);

  return (
    <div
      className={cx(styles.container, containerClassName)}
      style={containerStyle}
    >
      <table
        data-cy={dataCy}
        className={cx(styles.table, tableClassName)}
        style={tableStyle}
        data-rows-have-checkbox={rowsAreSelectable}
      >
        <TableHeader
          rowsAreSelectable={rowsAreSelectable}
          columns={columns}
          urlSearchParams={urlSearchParams}
          onToggleAllRowsSelection={() => onHeaderCheckboxSelect()}
          handleRowSort={handleRowSort}
          allSelected={allRowsSelected}
          actionsAvailable={withActions}
        />
        <tbody>{renderedRows}</tbody>
      </table>
    </div>
  );
}
