import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';

import { Input } from 'design-system-web';

import IconButton from '../general/IconButton';

import styles from './QuantityInput.scss';

type OwnProps = {
  className?: any;
  style?: any;
  loading?: boolean;
  disabled?: boolean;
  quantity: number;
  min?: number;
  max?: number;
  iconType: any;
  onChange: (value: number) => void;
};

type Props = OwnProps;

function QuantityInput({
  className,
  style,
  loading = false,
  disabled = false,
  quantity,
  min = 0,
  max = 999,
  iconType = FONT_AWESOME_REGULAR,
  onChange,
}: Props) {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  const _quantity = parseInt(quantity, 10) || 0;
  const _min = min !== null ? min : 1;
  const _max = max || 999;

  function handleSetManually(value: any) {
    const _value = parseInt(value, 10) || 0;
    if (_value > _max) {
      onChange(_max);
    } else {
      onChange(_value);
    }
  }

  return (
    <div
      className={cx(styles.QuantityInput, className)}
      style={style}
      data-testid="test-quantity-input"
    >
      <IconButton
        disabled={_quantity <= _min || disabled}
        loading={loading}
        className={styles.iconButton}
        icon="minus"
        iconSet="FontAwesome"
        inverted
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ disabled: boolean; loading: boolean; class... Remove this comment to see the full error message
        iconType={iconType}
        onClick={() => onChange(Math.max(_quantity - 1, _min))}
        dataCy="decrement-button"
        data-testid="test-decrement-button"
      />
      <Input
        className={styles.input}
        dataCy="quantity"
        value={_quantity.toString()}
        onChange={handleSetManually}
        showClear={false}
        disabled={disabled}
      />
      <IconButton
        disabled={_quantity >= max || disabled}
        loading={loading}
        className={styles.iconButton}
        icon="plus"
        inverted
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ disabled: boolean; loading: boolean; class... Remove this comment to see the full error message
        iconType={iconType}
        iconSet="FontAwesome"
        onClick={() => onChange(Math.min(_quantity + 1, _max))}
        dataCy="increment-button"
        data-testid="test-increment-button"
      />
    </div>
  );
}

QuantityInput.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  quantity: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default QuantityInput;
