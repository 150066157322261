import React, { useState } from 'react';
import { Modal, M, H4, IconButton, Button, S, Flex } from 'design-system-web';
import styles from './UpdateUserCompanyModal.scss';
import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';
import { CircleListView, Label, MultiselectField } from 'components';
import { useTranslation } from 'react-i18next';

type Props = {
  isModalOpen: boolean;
  setModalClose: (value: boolean) => void;
  selectedUser: { name: string; email: string };
};

type SelectedCompany = {
  value: string;
  label: string;
  isDisabled: string;
};

const TRANSLATION_KEYS = {
  modalTitle: 'web.admin.channel.users.all.table.updateCompany.modal.title',
  recommendText:
    'web.admin.channel.users.all.table.updateCompany.modal.content.recommend.text',
  updateButton: 'Update',
  cancelButton: 'Cancel',
  toastMessage:
    'web.admin.channel.users.all.table.updateCompany.modal.toast.message',
};

export const UpdateUserCompanyModal = ({
  isModalOpen,
  setModalClose,
  selectedUser,
}: Props) => {
  const { t } = useTranslation();
  const [selectedCompanies, setSelectedCompanies] = useState<SelectedCompany[]>(
    []
  );

  const companies = [
    {
      value: 'Company name 1',
      label: 'Company name 1',
    },
    {
      value: 'Company name 2',
      label: 'Company name 2',
    },
    {
      value: 'Company name 3',
      label: 'Company name 3',
    },
  ];

  const onCloseModal = () => {
    setModalClose(false);
  };

  const handleSelectCompany = (values: any) => {
    setSelectedCompanies(values);
  };

  const onUpdate = () => {
    window.Toast.show(t(TRANSLATION_KEYS.toastMessage), 5000);
    onCloseModal();
  };

  return (
    <Modal
      className={styles.UpdateUserCompanyModal}
      isOpen={isModalOpen}
      onClose={onCloseModal}
      isCloseButtonHidden
    >
      <Flex justify="space-between" align="center">
        <H4>{t(TRANSLATION_KEYS.modalTitle)}</H4>
        <IconButton
          className={styles.closeButton}
          type={FONT_AWESOME_REGULAR}
          onClick={onCloseModal}
          icon="times"
          data-testid="closeButton"
          data-cy="closeButton"
          size="small"
        />
      </Flex>

      <CircleListView
        image={null}
        logo={null}
        name={selectedUser.name}
        className={styles.circleListView}
      >
        <M>{`${selectedUser.name} (${selectedUser.email})`}</M>
      </CircleListView>

      <Flex align="center">
        <Label>Companies</Label>
        <span className={styles.required}>*</span>
      </Flex>

      <MultiselectField
        items={companies}
        value={selectedCompanies}
        onChange={handleSelectCompany}
        isFullWidth
      />

      <S className={styles.recommendText}>
        {t(TRANSLATION_KEYS.recommendText)}
      </S>

      <div className={styles.divider} />

      <Flex>
        <Button
          variant="primary"
          size="large"
          className={styles.updateButton}
          onClick={onUpdate}
        >
          {t(TRANSLATION_KEYS.updateButton)}
        </Button>
        <Button
          variant="secondary"
          size="large"
          className={styles.cancelButton}
          onClick={onCloseModal}
        >
          {t(TRANSLATION_KEYS.cancelButton)}
        </Button>
      </Flex>
    </Modal>
  );
};
