// Variant IconButton which appears in Onboarding Flows
import React from 'react';

import cx from 'classnames';

import Button from '../Button';

import styles from './VariantIconButton.scss';

type Props = {
  label: string;
  image?: React.ReactNode;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  dataCy?: string;
  color?: string;
};

export default function VariantIconButton({
  label,
  image,
  onClick,
  color,
}: Props) {
  return (
    <Button
      className={cx(styles.variantIconButton)}
      onClick={onClick}
      variant="outlined-light"
      style={{ color, borderColor: color }}
      fullWidth
    >
      {image ? (
        <div data-cy={label} className={styles.labelWrapper}>
          <span className={styles.image}>{image}</span>
          {label}
        </div>
      ) : (
        <div data-cy={label}>{label}</div>
      )}
    </Button>
  );
}
