import React, { useContext, useEffect, useState } from 'react';

import DOMPurify from 'dompurify';
import { i18n } from 'lane-shared';
import mjml2html from 'mjml-browser';
import { toDataURL } from 'qrcode';

import { renderToMjml } from '@faire/mjml-react/utils/renderToMjml';

import { formatAddress } from 'lane-infrastructure/email/helpers/formatters';

import { AppContext } from 'lane-shared/contexts';
import { EmailVisitorInvite } from 'lane-shared/domains/visitorManagement/emails';
import {
  ActivateEmailTemplate,
  EventStatus,
} from 'lane-shared/domains/visitorManagement/types';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { ChannelType } from 'lane-shared/types/ChannelType';

type Props = {
  channel: Partial<ChannelType>;
  emailTemplate: ActivateEmailTemplate | undefined;
};

export const VisitorManagementEmailTemplatePreview = ({
  channel,
  emailTemplate,
}: Props) => {
  const { whitelabel } = useContext(AppContext);
  const [credential, setCredential] = useState({
    data: '',
    uri: '',
  });

  useEffect(() => {
    let isMounted = true;
    async function generatePreviewQrCode() {
      const data = await toDataURL('email-preview-qrcode');
      if (isMounted) {
        setCredential(prevCredential => ({
          ...prevCredential,
          data,
        }));
      }
    }

    generatePreviewQrCode();

    return () => {
      isMounted = false;
    };
  }, []);

  const building = {
    name: '[Property name]',
    addressLineOne: '[Property’s address]',
    addressLineTwo: '',
    timezone: '',

    visitorInstructions: emailTemplate?.entryInstructions || '',
    visitorPolicies: emailTemplate?.policies || '',

    visitorHeroUri: imageUrl(emailTemplate?.backgroundId),
    logoUri: imageUrl(channel.profile?.logo),

    qrEnabled: emailTemplate?.qrEnabled ?? true,
    contactEmail: channel.profile?.email || '',
    mapUri: '#',
  };

  const host = {
    firstName: '[Host name]',
    lastName: '',
    email: 'host.name@email.com',
    companyName: '[Company name]',
  };

  const visitor = {
    firstName: '[Visitor name]',
    lastName: '',
    email: 'visitor.name@email.com',
    phone: '(555) 555-5555',
    company: '[Visitor Company]',
  };

  const visitorPass = {
    startDate: '[Duration of the visit]',
    endDate: '',
    startTime: '',
    endTime: '',
    status: EventStatus.EVENT_STATUS_UPCOMING,
    notesToVisitor: '[Unique messages to your guests will display here]',
  };

  const [whiteLabelAddressLineOne, whiteLabelAddressLineTwo] = formatAddress(
    whitelabel.address
  );

  const previewWhitelabel = {
    name: '[White label name]',
    contactEmail: whitelabel.profile.email,
    addressLineOne: whiteLabelAddressLineOne,
    addressLineTwo: whiteLabelAddressLineTwo,
  };

  const params = {
    building,
    host,
    visitorPass,
    visitor,
    credential,
    whitelabel: previewWhitelabel,
  };

  const previewMjml = renderToMjml(
    <EmailVisitorInvite title="Email Preview" params={params} i18n={i18n} />
  );

  const previewHtml = mjml2html(previewMjml, { validationLevel: 'soft' });

  const sanitizedHtml = DOMPurify.sanitize(previewHtml.html);

  return (
    /* 
      Dangerously setting html below in order to have the email preview look similar 
      to what gets rendered in the email client (gmail, apple mail, etc).
      The mjml components used to build the email cannot be rendered directly in react hence the need for converting to html.
      The html has been sanitized using DOMPurify to prevent any attacks.
    */
    // eslint-disable-next-line react/no-danger
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      data-cy="emailTemplatePreview"
    />
  );
};
