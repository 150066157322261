import { audienceEvents } from './audience.events';
import { draftContentEvents } from './draftContent.events';
import {
  serviceRequestEventKeys,
  serviceRequestEvents,
} from './serviceRequest.events';
import { taskEventKeys, taskEvents } from './task.events';
import { underConstructionEvents } from './underConstruction.events';

export const EVENT_CONTENT_INTERACTION_CREATED = 'content.interaction.created';
export const EVENT_CONTENT_INTERACTION_UPDATED = 'content.interaction.updated';
export const EVENT_CONTENT_INTERACTION_STARTED = 'content.interaction.started';
export const EVENT_CONTENT_INTERACTION_ENDED = 'content.interaction.ended';
export const EVENT_CONTENT_INTERACTION_STATUSCHANGE =
  'content.interaction.statusChange';
export const EVENT_CONTENT_INTERACTION_DELETED = 'content.interaction.deleted';
export const EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED =
  'content.interaction.feature.Cancellable.cancelled';
export const EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_CREATED =
  'content.interaction.feature.Payment.created';
export const EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_REFUNDED =
  'content.interaction.feature.Payment.refunded';
export const EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_DISPUTED =
  'content.interaction.feature.Payment.disputed';
export const EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_DISPUTEDRETURNED =
  'content.interaction.feature.Payment.disputedReturned';
export const EVENT_CONTENT_INTERACTION_NOTIFICATION =
  'content.interaction.notification';
export const EVENT_CONTENT_FEATURE_INVENTORY_TAKEN =
  'content.feature.Inventory.taken';
export const EVENT_CONTENT_FEATURE_INVENTORY_RETURNED =
  'content.feature.Inventory.returned';
export const EVENT_CONTENT_FEATURE_INVENTORY_EMPTY =
  'content.feature.Inventory.empty';
export const EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_SCANNED =
  'content.interaction.feature.QRCodeCheckin.scanned';
export const EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_CHECKIN =
  'content.interaction.feature.QRCodeCheckin.checkin';
export const EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_CHECKOUT =
  'content.interaction.feature.QRCodeCheckin.checkout';
export const EVENT_CONTENT_FEATURE_QUANTITY_TAKEN =
  'content.feature.Quantity.taken';
export const EVENT_CONTENT_FEATURE_QUANTITY_EMPTY =
  'content.feature.Quantity.empty';
export const EVENT_CONTENT_FEATURE_REMOTEFETCH_EXECUTED =
  'content.feature.RemoteFetch.executed';
export const EVENT_CONTENT_FEATURE_REMOTEFETCH_ERROR =
  'content.feature.RemoteFetch.error';
export const EVENT_CONTENT_FEATURE_RESET_RESET = 'content.feature.Reset.reset';
export const EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION =
  'content.workorder.servicerequest.escalation';
export const EVENT_USER_CHANNEL_NEAR = 'user.channel.near';
export const EVENT_USER_CHANNEL_SIGNIN = 'user.channel.signIn';
export const EVENT_USER_CHANNEL_SIGNOUT = 'user.channel.signOut';
export const EVENT_USER_CREATED = 'user.created';
export const EVENT_USER_UPDATED = 'user.updated';
export const EVENT_USER_SUPER_GRANTED = 'user.super.granted';
export const EVENT_USER_SUPER_REVOKED = 'user.super.revoked';
export const EVENT_USER_UPDATED_LOCALE = 'user.updated.locale';
export const EVENT_USER_COMPLETESIGNUP = 'user.completeSignup';
export const EVENT_USER_CONFIRMED = 'user.confirmed';
export const EVENT_USER_UNSUBSCRIBED = 'user.unsubscribed';
export const EVENT_USER_SIGNIN = 'user.signIn';
export const EVENT_USER_PRESENCE = 'user.presence';
export const EVENT_USER_IDLE = 'user.idle';
export const EVENT_USER_FORGOTPASSWORD = 'user.forgotPassword';
export const EVENT_USER_RESETPASSWORD = 'user.resetPassword';
export const EVENT_USER_SIGNOUT = 'user.signOut';
export const EVENT_USER_LOGIN_CREATED = 'user.login.created';
export const EVENT_USER_LOGIN_VERIFIED = 'user.login.verified';
export const EVENT_USER_LOGIN_VERIFIED_FAIL = 'user.login.verifiedFail';
export const EVENT_USER_LOGIN_DISABLED = 'user.login.disabled';
export const EVENT_USER_LOGIN_BOUNCED = 'user.login.bounced';
export const EVENT_USER_PAYMENT_ADDED = 'user.payment.added';
export const EVENT_USER_PAYMENT_REMOVED = 'user.payment.removed';
export const EVENT_USER_EMAIL_OPENED = 'user.email.opened';
export const EVENT_USER_UPDATE_SESSION = 'user.updated.session';
export const EVENT_CHANNEL_CREATED = 'channel.created';
export const EVENT_CHANNEL_UPDATED = 'channel.updated';
export const EVENT_CHANNEL_DELETED = 'channel.deleted';
export const EVENT_CHANNEL_MERCHANT_CREATED = 'channel.merchant.created';
export const EVENT_CHANNEL_MERCHANT_UPDATED = 'channel.merchant.updated';
export const EVENT_CHANNEL_MERCHANT_STATUS = 'channel.merchant.status';
export const EVENT_CHANNEL_PAYMENT_ADDED = 'channel.payment.added';
export const EVENT_CHANNEL_PAYMENT_REMOVED = 'channel.payment.removed';
export const EVENT_CHANNEL_RESERVABLE_MANAGER_ENABLED =
  'channel.settings.reservableManager.enabled';
export const EVENT_CHANNEL_WORKPLACE_MEMBER_JOINED =
  'channel.subscriber.joined';
export const EVENT_CHANNEL_WORKPLACE_MEMBER_LEFT = 'channel.subscriber.left';
export const EVENT_CHANNEL_ROLE_CREATED = 'channel.role.created';
export const EVENT_CHANNEL_ROLE_UPDATED = 'channel.role.updated';
export const EVENT_CHANNEL_ROLE_DELETED = 'channel.role.deleted';
export const EVENT_CHANNEL_ROLE_JOINED = 'channel.role.joined';
export const EVENT_CHANNEL_ROLE_LEFT = 'channel.role.left';
export const EVENT_CHANNEL_ROLE_SIGNUP_SENT = 'channel.role.signup.sent';
export const EVENT_CHANNEL_ROLE_INVITE_SENT = 'channel.role.invite.sent';
export const EVENT_CHANNEL_ROLE_INVITE_RESENT = 'channel.role.invite.resent';
export const EVENT_CHANNEL_ROLE_INVITE_CLAIMED = 'channel.role.invite.claimed';
export const EVENT_CHANNEL_ROLE_INVITE_DELETED = 'channel.role.invite.deleted';
export const EVENT_CHANNEL_RELATIONSHIP_CREATED =
  'channel.relationship.created';
export const EVENT_CHANNEL_RELATIONSHIP_DELETED =
  'channel.relationship.deleted';
export const EVENT_CHANNEL_RELATIONSHIP_REQUESTED =
  'channel.relationship.requested';
export const EVENT_CHANNEL_RELATIONSHIP_APPROVED =
  'channel.relationship.approved';
export const EVENT_CHANNEL_RELATIONSHIP_REJECTED =
  'channel.relationship.rejected';
export const EVENT_CONTENT_CREATED = 'content.created';
export const EVENT_CONTENT_UPDATED = 'content.updated';
export const EVENT_CONTENT_DELETED = 'content.deleted';
export const EVENT_CONTENT_PUBLISHED = 'content.published';
export const EVENT_CONTENT_UNPUBLISHED = 'content.unpublished';
export const EVENT_CONTENT_APPROVED = 'content.approved';
export const EVENT_CONTENT_REJECTED = 'content.rejected';
export const EVENT_RESERVABLE_CONTENT_PUBLISHED =
  'content.reservable.published';
export const EVENT_CHANNEL_SECTION_CREATED = 'section.created';
export const EVENT_CHANNEL_SECTION_UPDATED = 'section.updated';
export const EVENT_CHANNEL_SECTION_DELETED = 'section.deleted';
export const EVENT_CONTENT_STATIC_PUBLISH = 'content.static.publish';
export const EVENT_CONTENT_LIVE = 'content.live';
export const EVENT_CONTENT_NOTIFICATION = 'content.notification';
export const EVENT_CONTENT_STARTED = 'content.started';
export const EVENT_CONTENT_ENDED = 'content.ended';
export const EVENT_CONTENT_ENDED_INTERACTION_DATE = 'content.interactionEnded';
export const EVENT_CONTENT_NOTICE_LIVE = 'content.notice.live';
export const EVENT_CONTENT_NOTICE_NOTIFICATION = 'content.notice.notification';
export const EVENT_CONTENT_NOTICE_STARTED = 'content.notice.started';
export const EVENT_CONTENT_NOTICE_ENDED = 'content.notice.ended';
export const EVENT_CONTENT_PROMOTION_LIVE = 'content.promotion.live';
export const EVENT_CONTENT_PROMOTION_NOTIFICATION =
  'content.promotion.notification';
export const EVENT_CONTENT_PROMOTION_STARTED = 'content.promotion.started';
export const EVENT_CONTENT_PROMOTION_ENDED = 'content.promotion.ended';
export const EVENT_CONTENT_PERK_LIVE = 'content.perk.live';
export const EVENT_CONTENT_PERK_NOTIFICATION = 'content.perk.notification';
export const EVENT_CONTENT_PERK_STARTED = 'content.perk.started';
export const EVENT_CONTENT_PERK_ENDED = 'content.perk.ended';
export const EVENT_CONTENT_PLACEMENT_REQUEST = 'content.placement.request';
export const EVENT_CONTENT_PLACEMENT_APPROVED = 'content.placement.approved';
export const EVENT_CONTENT_PLACEMENT_REJECTED = 'content.placement.rejected';
export const EVENT_CONTENT_PLACEMENT_CREATED = 'content.placement.created';
export const EVENT_CONTENT_PLACEMENT_DELETED = 'content.placement.deleted';
export const EVENT_CONTENTTEMPLATE_CREATED = 'contentTemplate.created';
export const EVENT_CONTENTTEMPLATE_UPDATED = 'contentTemplate.updated';
export const EVENT_CONTENTTEMPLATE_DELETED = 'contentTemplate.deleted';
export const EVENT_MEDIA_CREATED = 'media.created';
export const EVENT_MEDIA_UPDATED = 'media.updated';
export const EVENT_MEDIA_DELETED = 'media.deleted';
export const EVENT_LIBRARY_CREATED = 'library.created';
export const EVENT_LIBRARY_UPDATED = 'library.updated';
export const EVENT_LIBRARY_DELETED = 'library.deleted';
export const EVENT_LIBRARY_ITEM_CREATED = 'library.item.created';
export const EVENT_LIBRARY_ITEM_DELETED = 'library.item.deleted';
export const EVENT_LIBRARY_ITEM_UPDATED = 'library.item.updated';
export const EVENT_FEEDBACK_FORM_CREATED = 'feedback.created';
export const EVENT_INTEGRATION_CREATED = 'integration.created';
export const EVENT_INTEGRATION_UPDATED = 'integration.updated';
export const EVENT_INTEGRATION_DELETED = 'integration.deleted';
export const EVENT_INTEGRATION_LOGIN = 'integration.login';
export const EVENT_INTEGRATION_INTERACTION = 'integration.interaction';
export const EVENT_WORK_ORDER_CREATED = 'workOrder.created';
export const EVENT_WORK_ORDER_COMMENT_ADDED = 'workOrder.commentAdded';
export const EVENT_FILE_UPLOADED = 'file.uploaded';
export const EVENT_VISIT_CREATED = 'visitorManagement.created';
export const EVENT_CHANNEL_INTEGRATION_CREATED = 'channel.integration.created';
export const EVENT_CHANNEL_INTEGRATION_UPDATED = 'channel.integration.updated';
export const EVENT_CHANNEL_INTEGRATION_DELETED = 'channel.integration.deleted';
export const EVENT_CHANNEL_INTEGRATION_INTERACTION =
  'channel.integration.interaction';
export const EVENT_METATAG_CREATED = 'metatag.created';
export const EVENT_METATAG_UPDATED = 'metatag.updated';
export const EVENT_METATAG_DELETED = 'metatag.deleted';
export const EVENT_DATASET_SCHEMA_CREATED = 'dataset.schema.created';
export const EVENT_DATASET_SCHEMA_UPDATED = 'dataset.schema.updated';
export const EVENT_DATASET_SCHEMA_DELETED = 'dataset.schema.deleted';
export const EVENT_DATASET_RECORD_CREATED = 'dataset.record.created';
export const EVENT_DATASET_RECORD_UPDATED = 'dataset.record.updated';
export const EVENT_DATASET_RECORD_DELETED = 'dataset.record.deleted';
export const EVENT_WEBHOOK_CREATED = 'webhook.created';
export const EVENT_WEBHOOK_UPDATED = 'webhook.updated';
export const EVENT_WEBHOOK_DELETED = 'webhook.deleted';
export const EVENT_WEBHOOK_FIRED = 'webhook.fired';
export const EVENT_WEBHOOK_SUCCESS = 'webhook.success';
export const EVENT_WEBHOOK_FAILED = 'webhook.failed';
export const EVENT_API_CHANNEL_USER_SYNCHRONIZED =
  'api.channelUser.synchronized';
export const EVENT_PHONE_NUMBER_UPDATED = 'phonenumber.updated';
export const EVENT_PHONE_NUMBER_CREATED = 'phonenumber.created';
export const EVENT_LOGIN_PHONE_NUMBER_CREATED = 'phonenumber.login.created';
export const EVENT_LOGIN_PHONE_NUMBER_VERIFIED =
  'phonenumber.login.created.verified';
export const EVENT_LOGIN_PHONE_NUMBER_DELETED =
  'phonenumber.login.verified.deleted';
export const EVENT_EMAIL_CREATED = 'email.created';
export const EVENT_EMAIL_UPDATED = 'email.updated';
export const EVENT_LOGIN_EMAIL_VERIFIED = 'email.login.verified';
export const EVENT_LOGIN_EMAIL_CREATED = 'email.login.created';
export const EVENT_LOGIN_EMAIL_DELETED = 'email.login.deleted';
export const EVENT_MEMBER_INVITED_TO_GROUP_ROLE = 'grouprole.member.invited';
export const EVENT_MEMBER_ADDED_TO_GROUP_ROLE = 'grouprole.member.added';
export const EVENT_MEMBER_DELETED_FROM_GROUP_ROLE = 'grouprole.member.deleted';
export const EVENT_JOINED_CHANNEL = 'channel.joined';
export const EVENT_SWITCHED_CHANNEL = 'channel.switched';
export const EVENT_NOTIFICATION_PREFERENCES_UPDATED =
  'notification.preferences.updated';
export const EVENT_USER_DELETED = 'user.deleted';
export const EVENT_INACTIVE_USER_DELETED = 'inactiveuser.deleted';
export const EVENT_USER_DISABLED = 'user.disabled';
export const EVENT_USER_ENABLED = 'user.enabled';

export const EVENT_CONTENT_RESERVABLE_WAITLIST_ENABLED =
  'content.reservable.waitlist.enabled';
export const EVENT_CONTENT_USER_WAITLIST_JOIN =
  'content.reservable.waitlist.join';
export const EVENT_CONTENT_USER_WAITLIST_LEAVE =
  'content.reservable.waitlist.leave';
export const EVENT_USER_WAITLIST_CONVERSION = 'waitlist.user.converted';

export const EVENT_CONTENT_QUANTITY_WAITLIST_ENABLED =
  'content.quantity.waitlist.enabled';
export const EVENT_CONTENT_QUANTITY_USER_WAITLIST_JOIN =
  'content.quantity.waitlist.join';
export const EVENT_CONTENT_QUANTITY_USER_WAITLIST_LEAVE =
  'content.quantity.waitlist.leave';

export const EVENT_PIN_SECTION_CONTENT = 'section.preview.pin';
export const EVENT_UNPIN_SECTION_CONTENT = 'section.preview.unpin';
export const EVENT_VIEW_BUTTON_CLICK_SECTION_CONTENT =
  'section.preview.view-content-button';
export const EVENT_REORDER_SECTION_CONTENT = 'section.preview.reorder';

export const [
  EVENT_SERVICE_REQUEST_CREATED,
  EVENT_SERVICE_REQUEST_STATUS_UPDATED,
  EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
  EVENT_SERVICE_REQUEST_ASSIGNED,
  EVENT_SERVICE_REQUEST_UNASSIGNED,
] = serviceRequestEventKeys;

export const [
  EVENT_TASK_CREATED,
  EVENT_TASK_STATUS_UPDATED,
  EVENT_TASK_NON_STATUS_UPDATED,
  EVENT_TASK_ASSIGNED,
  EVENT_TASK_UNASSIGNED,
] = taskEventKeys;

const events = [
  {
    key: EVENT_CONTENT_INTERACTION_CREATED,
    description: 'An interaction was created for this content piece.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_UPDATED,
    description: 'An interaction was updated.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_STARTED,
    description: 'An interaction has started.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_ENDED,
    description: 'An interaction has ended.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_STATUSCHANGE,
    description: 'A status changed on an interaction.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_DELETED,
    description: 'A an interaction was deleted.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED,
    description: 'An interaction with Cancellable feature has been cancelled.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_CREATED,
    description:
      'An interaction with Payment enabled has been created / purchased.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_REFUNDED,
    description: 'An interaction with Payment enabled has been refunded.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_DISPUTED,
    description:
      'An interaction with Payment enabled had the payment disputed.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_DISPUTEDRETURNED,
    description:
      'An interaction with Payment enabled had a disputed payment returned.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_NOTIFICATION,
    description:
      'A notification is being sent related to a content interaction.',
  },
  {
    key: EVENT_CONTENT_FEATURE_INVENTORY_TAKEN,
    description: 'Content with Inventory enabled has had an item taken.',
  },
  {
    key: EVENT_CONTENT_FEATURE_INVENTORY_RETURNED,
    description: 'Content with Inventory enabled had an item returned.',
  },
  {
    key: EVENT_CONTENT_FEATURE_INVENTORY_EMPTY,
    description: 'A content with Inventory enabled is out of inventory.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_CHECKIN,
    description:
      'A User has checked in from an interaction with QRCode check in enabled.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_CHECKOUT,
    description:
      'A User has checked out from an interaction with QRCodes check out enabled.',
  },
  {
    key: EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_SCANNED,
    description:
      'An interaction with QRCodes enabled has been scanned by a user.',
    userPermitted: true,
  },
  {
    key: EVENT_CONTENT_FEATURE_QUANTITY_TAKEN,
    description: 'A content with Quantity has had some quantity taken.',
  },
  {
    key: EVENT_CONTENT_FEATURE_QUANTITY_EMPTY,
    description: 'A content with Quantity enabled is empty.',
  },
  {
    key: EVENT_CONTENT_FEATURE_REMOTEFETCH_EXECUTED,
    description:
      'A content with RemoteFetch enabled had the remote fetch execute successfully.',
  },
  {
    key: EVENT_CONTENT_FEATURE_REMOTEFETCH_ERROR,
    description: 'A content with RemoteFetch enabled had a remote fetch fail.',
  },
  {
    key: EVENT_CONTENT_FEATURE_RESET_RESET,
    description: 'A content with Reset enabled hit its reset period.',
  },
  {
    key: EVENT_USER_CHANNEL_NEAR,
    description:
      'A User is near (by geo-location) a Channel they are subscribed to.',
    userPermitted: true,
  },
  {
    key: EVENT_USER_CHANNEL_SIGNIN,
    description:
      'A User has signed into this Channel (ie. their mobile app has switched to this building.',
    userPermitted: true,
  },
  {
    key: EVENT_USER_CHANNEL_SIGNOUT,
    description:
      'A User has signed out this Channel (ie. their mobile app has switched await from this building.',
    userPermitted: true,
  },
  {
    key: EVENT_USER_CREATED,
    description: 'A User has been created on the platform',
  },
  {
    key: EVENT_USER_UPDATED,
    description: 'A User has been updated on the platform',
  },
  {
    key: EVENT_USER_SUPER_GRANTED,
    description: 'A User was granted super user permissions',
  },
  {
    key: EVENT_USER_SUPER_REVOKED,
    description: 'A User had super user permissions revoked',
  },
  {
    key: EVENT_USER_CONFIRMED,
    description: 'A new user has confirmed their account.',
  },
  {
    key: EVENT_USER_UNSUBSCRIBED,
    description: 'A user has unsubscribed from all emails.',
  },
  {
    key: EVENT_USER_SIGNIN,
    description: 'A User has signed into the platform',
  },
  {
    key: EVENT_USER_PRESENCE,
    description: 'A User is present on the platform. ie. opened the app',
    userPermitted: true,
  },
  {
    key: EVENT_USER_IDLE,
    description: 'A User is now idle on the platform.',
    userPermitted: true,
  },
  {
    key: EVENT_USER_FORGOTPASSWORD,
    description: 'A User asked for a password reset.',
  },
  {
    key: EVENT_USER_RESETPASSWORD,
    description: 'A User used a password reset.',
  },
  {
    key: EVENT_USER_SIGNOUT,
    description: 'A User has signed out of the platform',
  },
  {
    key: EVENT_USER_LOGIN_CREATED,
    description: 'A new User Login has been created',
  },
  {
    key: EVENT_USER_LOGIN_VERIFIED,
    description: 'A User Login has been verified',
  },
  {
    key: EVENT_USER_LOGIN_VERIFIED_FAIL,
    description: 'A User Login verification failed',
  },
  {
    key: EVENT_USER_LOGIN_DISABLED,
    description: 'A User Login has been disabled',
  },
  {
    key: EVENT_USER_LOGIN_BOUNCED,
    description: 'A Users email has bounced back.',
  },
  {
    key: EVENT_USER_PAYMENT_ADDED,
    description: 'A User has added a payment method.',
  },
  {
    key: EVENT_USER_PAYMENT_REMOVED,
    description: 'A User has removed a payment method.',
  },
  {
    key: EVENT_USER_EMAIL_OPENED,
    description: 'A User has opened an email.',
  },
  {
    key: EVENT_CHANNEL_CREATED,
    description: 'A Channel has been created',
  },
  {
    key: EVENT_CHANNEL_UPDATED,
    description: 'A Channel has been updated',
  },
  {
    key: EVENT_CHANNEL_DELETED,
    description: 'A Channel has been deleted',
  },
  {
    key: EVENT_CHANNEL_MERCHANT_CREATED,
    description: 'A Channel merchant account has been created',
  },
  {
    key: EVENT_CHANNEL_MERCHANT_UPDATED,
    description: 'A Channel merchant account has been updated',
  },
  {
    key: EVENT_CHANNEL_MERCHANT_STATUS,
    description: 'A Channel merchant account status has been updated',
  },
  {
    key: EVENT_CHANNEL_PAYMENT_ADDED,
    description: 'A Channel payment account account has been added',
  },
  {
    key: EVENT_CHANNEL_PAYMENT_REMOVED,
    description: 'A Channel payment account has been removed',
  },
  {
    key: EVENT_CHANNEL_RESERVABLE_MANAGER_ENABLED,
    description: 'A Super User has enabled Reservable Manager for a Channel',
  },
  {
    key: EVENT_CHANNEL_WORKPLACE_MEMBER_JOINED,
    description: 'A User has joined as a Workplace Member on a Channel',
  },
  {
    key: EVENT_CHANNEL_WORKPLACE_MEMBER_LEFT,
    description: 'A User has left as a Workplace Member on a Channel',
  },
  {
    key: EVENT_CHANNEL_ROLE_CREATED,
    description: 'A new GroupRole has been created on a Channel',
  },
  {
    key: EVENT_CHANNEL_ROLE_UPDATED,
    description: 'A GroupRole has been updated on a Channel',
  },
  {
    key: EVENT_CHANNEL_ROLE_DELETED,
    description: 'A GroupRole has been deleted from a Channel',
  },
  {
    key: EVENT_CHANNEL_ROLE_JOINED,
    description: 'A User has joined a GroupRole on a Channel',
  },
  {
    key: EVENT_CHANNEL_ROLE_LEFT,
    description: 'A User has lett a GroupRole on a Channel',
  },
  {
    key: EVENT_CHANNEL_ROLE_SIGNUP_SENT,
    description: 'A confirm sign-up email has been sent for this user',
  },
  {
    key: EVENT_CHANNEL_ROLE_INVITE_SENT,
    description: 'An Invite has been sent for a GroupRole on a Channel',
  },
  {
    key: EVENT_CHANNEL_ROLE_INVITE_RESENT,
    description: 'An Invite has been re-sent for a GroupRole on a Channel',
  },
  {
    key: EVENT_CHANNEL_ROLE_INVITE_CLAIMED,
    description: 'An Invite has been claimed for a GroupRole on a Channel',
  },
  {
    key: EVENT_CHANNEL_ROLE_INVITE_DELETED,
    description: 'An Invite has been deleted for a GroupRole on a Channel',
  },
  {
    key: EVENT_CHANNEL_RELATIONSHIP_CREATED,
    description: 'A new relationship has been created between two Channels',
  },
  {
    key: EVENT_CHANNEL_RELATIONSHIP_DELETED,
    description: 'A relationship has been removed from two Channels',
  },
  {
    key: EVENT_CHANNEL_RELATIONSHIP_REQUESTED,
    description:
      'A new relationship has been requested from a Channel to another Channel',
  },
  {
    key: EVENT_CHANNEL_RELATIONSHIP_APPROVED,
    description:
      'A relationship request has been approved from a Channel to another Channel',
  },
  {
    key: EVENT_CHANNEL_RELATIONSHIP_REJECTED,
    description:
      'A relationship request has been rejected from a Channel to another Channel',
  },
  {
    key: EVENT_CONTENT_CREATED,
    description: 'Content has been created on this channel.',
  },
  {
    key: EVENT_CONTENT_UPDATED,
    description: 'Content has been updated on this channel.',
  },
  {
    key: EVENT_CONTENT_DELETED,
    description: 'Content has been deleted from this channel.',
  },
  {
    key: EVENT_CONTENT_PUBLISHED,
    description: 'Content has been published on this channel.',
  },
  {
    key: EVENT_CONTENT_UNPUBLISHED,
    description: 'Content has been un-published from this channel.',
  },
  {
    key: EVENT_CONTENT_APPROVED,
    description: 'Content has been approved for publish on from this channel.',
  },
  {
    key: EVENT_CONTENT_REJECTED,
    description: 'Content has been rejected for publish on from this channel.',
  },
  {
    key: EVENT_RESERVABLE_CONTENT_PUBLISHED,
    description: 'Reservable content has been published on this channel.',
  },
  {
    key: EVENT_CHANNEL_SECTION_CREATED,
    description: 'A new section has been created on this channel.',
  },
  {
    key: EVENT_CHANNEL_SECTION_UPDATED,
    description: 'A section has been updated on this channel.',
  },
  {
    key: EVENT_CHANNEL_SECTION_DELETED,
    description: 'A section has been deleted on this channel.',
  },
  {
    key: EVENT_CONTENT_STATIC_PUBLISH,
    description: 'Static content has been published on this channel.',
  },
  {
    key: EVENT_CONTENT_LIVE,
    description: 'Content has gone live on this Channel.',
  },
  {
    key: EVENT_CONTENT_NOTIFICATION,
    description: 'Content has sent a notification.',
  },
  {
    key: EVENT_CONTENT_STARTED,
    description: 'Content has started on this channel.',
  },
  {
    key: EVENT_CONTENT_ENDED,
    description: 'Content has ended on this channel.',
  },
  {
    key: EVENT_CONTENT_ENDED_INTERACTION_DATE,
    description: 'Content interaction date has ended.',
  },
  {
    key: EVENT_CONTENT_NOTICE_LIVE,
    description: 'A Notice has gone live on this channel.',
  },
  {
    key: EVENT_CONTENT_NOTICE_NOTIFICATION,
    description: 'A Notice has sent a notification.',
  },
  {
    key: EVENT_CONTENT_NOTICE_STARTED,
    description: 'A Notice has started on this channel.',
  },
  {
    key: EVENT_CONTENT_NOTICE_ENDED,
    description: 'A Notice has ended on this channel.',
  },
  {
    key: EVENT_CONTENT_PROMOTION_LIVE,
    description: 'A promotion has gone live on this channel.',
  },
  {
    key: EVENT_CONTENT_PROMOTION_NOTIFICATION,
    description: 'A Promotion has sent a notification.',
  },
  {
    key: EVENT_CONTENT_PROMOTION_STARTED,
    description: 'A promotion has started on this channel.',
  },
  {
    key: EVENT_CONTENT_PROMOTION_ENDED,
    description: 'A promotion has ended on this channel.',
  },
  {
    key: EVENT_CONTENT_PERK_LIVE,
    description: 'A perk has gone live on this channel.',
  },
  {
    key: EVENT_CONTENT_PERK_NOTIFICATION,
    description: 'A Perk has sent a notification.',
  },
  {
    key: EVENT_CONTENT_PERK_STARTED,
    description: 'A perk has started on this channel.',
  },
  {
    key: EVENT_CONTENT_PERK_ENDED,
    description: 'A perk has ended on this channel.',
  },
  {
    key: EVENT_CONTENT_PLACEMENT_REQUEST,
    description: 'A request to place content on a Channel has been created.',
  },
  {
    key: EVENT_CONTENT_PLACEMENT_APPROVED,
    description: 'A request to place content on a Channel has been approved.',
  },
  {
    key: EVENT_CONTENT_PLACEMENT_REJECTED,
    description: 'A request to place content on a Channel has been rejected.',
  },
  {
    key: EVENT_CONTENT_PLACEMENT_CREATED,
    description: 'Content has been placed on a Channel, GroupRole or User.',
  },
  {
    key: EVENT_CONTENT_PLACEMENT_DELETED,
    description: 'Content has been removed from a Channel, GroupRole or User.',
  },
  {
    key: EVENT_CONTENTTEMPLATE_CREATED,
    description: 'A Content Template was created.',
  },
  {
    key: EVENT_CONTENTTEMPLATE_UPDATED,
    description: 'A Content Template was updated.',
  },
  {
    key: EVENT_CONTENTTEMPLATE_DELETED,
    description: 'A Content Template was deleted.',
  },
  {
    key: EVENT_MEDIA_CREATED,
    description: 'A Media was created',
  },
  {
    key: EVENT_MEDIA_UPDATED,
    description: 'A Media was updated',
  },
  {
    key: EVENT_MEDIA_DELETED,
    description: 'A Media was deleted',
  },
  {
    key: EVENT_LIBRARY_CREATED,
    description: 'A Library was created',
  },
  {
    key: EVENT_LIBRARY_UPDATED,
    description: 'A Library was updated',
  },
  {
    key: EVENT_LIBRARY_DELETED,
    description: 'A Library was deleted',
  },
  {
    key: EVENT_LIBRARY_ITEM_CREATED,
    description: 'A library item was created.',
  },
  {
    key: EVENT_LIBRARY_ITEM_DELETED,
    description: 'A library item was deleted.',
  },
  {
    key: EVENT_LIBRARY_ITEM_UPDATED,
    description: 'A library item was updated.',
  },
  {
    key: EVENT_FEEDBACK_FORM_CREATED,
    description: 'A feedback form item was created.',
  },
  {
    key: EVENT_INTEGRATION_CREATED,
    description: 'An integration was created',
  },
  {
    key: EVENT_INTEGRATION_UPDATED,
    description: 'An integration was updated',
  },
  {
    key: EVENT_INTEGRATION_DELETED,
    description: 'An integration was deleted',
  },
  {
    key: EVENT_INTEGRATION_LOGIN,
    description: 'A user logged into an integration',
  },
  {
    key: EVENT_INTEGRATION_INTERACTION,
    description: 'An integration had an interaction',
  },
  {
    key: EVENT_WORK_ORDER_CREATED,
    description: 'A work order was created',
  },
  {
    key: EVENT_WORK_ORDER_COMMENT_ADDED,
    description: 'A comment was added to a work order',
  },
  {
    key: EVENT_FILE_UPLOADED,
    description: 'A file was uploaded',
  },
  {
    key: EVENT_VISIT_CREATED,
    description: 'A visit was created',
  },
  {
    key: EVENT_CHANNEL_INTEGRATION_CREATED,
    description: 'A Channel integration was created',
  },
  {
    key: EVENT_CHANNEL_INTEGRATION_UPDATED,
    description: 'A Channel integration was updated',
  },
  {
    key: EVENT_CHANNEL_INTEGRATION_DELETED,
    description: 'A Channel integration was deleted',
  },
  {
    key: EVENT_CHANNEL_INTEGRATION_INTERACTION,
    description: 'A Channel integration had an interaction',
  },
  {
    key: EVENT_METATAG_CREATED,
    description: 'A Metatag was created',
  },
  {
    key: EVENT_METATAG_UPDATED,
    description: 'A Metatag was updated',
  },
  {
    key: EVENT_METATAG_DELETED,
    description: 'A Metatag was deleted',
  },
  {
    key: EVENT_DATASET_SCHEMA_CREATED,
    description: 'A Dataset Schema was created',
  },
  {
    key: EVENT_DATASET_SCHEMA_UPDATED,
    description: 'A Dataset Schema was updated',
  },
  {
    key: EVENT_DATASET_SCHEMA_DELETED,
    description: 'A Dataset Schema was deleted',
  },
  {
    key: EVENT_DATASET_RECORD_CREATED,
    description: 'A Dataset Record was created',
  },
  {
    key: EVENT_DATASET_RECORD_UPDATED,
    description: 'A Dataset Record was updated',
  },
  {
    key: EVENT_DATASET_RECORD_DELETED,
    description: 'A Dataset Record was deleted',
  },
  {
    key: EVENT_WEBHOOK_CREATED,
    description: 'A Webhook was created',
  },
  {
    key: EVENT_WEBHOOK_UPDATED,
    description: 'A Webhook was updated',
  },
  {
    key: EVENT_WEBHOOK_DELETED,
    description: 'A Webhook was deleted',
  },
  {
    key: EVENT_WEBHOOK_FIRED,
    description: 'A Webhook was fired',
  },
  {
    key: EVENT_WEBHOOK_SUCCESS,
    description: 'A Webhook call was successful',
  },
  {
    key: EVENT_WEBHOOK_FAILED,
    description: 'A Webhook call failed',
  },
  {
    key: EVENT_API_CHANNEL_USER_SYNCHRONIZED,
    description: 'A Channel User has been synchronized through API',
  },
  {
    key: EVENT_PHONE_NUMBER_UPDATED,
    description: 'A user phone number has been updated',
  },
  {
    key: EVENT_PHONE_NUMBER_CREATED,
    description: 'A user phone number has been created',
  },
  {
    key: EVENT_LOGIN_PHONE_NUMBER_VERIFIED,
    description: 'A user login phone number has been verified',
  },
  {
    key: EVENT_LOGIN_PHONE_NUMBER_DELETED,
    description: 'A user login phone number has been DELETED',
  },

  {
    key: EVENT_LOGIN_PHONE_NUMBER_CREATED,
    description: 'A user phone login number has been added',
  },
  {
    key: EVENT_EMAIL_CREATED,
    description: 'A user email has been created',
  },

  {
    key: EVENT_EMAIL_UPDATED,
    description: 'A user email has been updated',
  },
  {
    key: EVENT_LOGIN_EMAIL_CREATED,
    description: 'A user login email has been created',
  },

  {
    key: EVENT_LOGIN_EMAIL_VERIFIED,
    description: 'A user login email has been verified',
  },
  {
    key: EVENT_LOGIN_EMAIL_DELETED,
    description: 'A user login email has been deleted',
  },
  {
    key: EVENT_MEMBER_ADDED_TO_GROUP_ROLE,
    description: 'A user has been added to a team',
  },
  {
    key: EVENT_MEMBER_DELETED_FROM_GROUP_ROLE,
    description: 'A user has been removed from a team',
  },
  {
    key: EVENT_MEMBER_INVITED_TO_GROUP_ROLE,
    description: 'A user has been invited to a team',
  },
  {
    key: EVENT_JOINED_CHANNEL,
    description: 'A user joined a channel',
  },
  {
    key: EVENT_SWITCHED_CHANNEL,
    description: 'A user has switched channels',
  },
  {
    key: EVENT_NOTIFICATION_PREFERENCES_UPDATED,
    description: 'User has updated notification delivery methods',
  },
  {
    key: EVENT_USER_DELETED,
    description: 'A user has been deleted',
  },
  {
    key: EVENT_INACTIVE_USER_DELETED,
    description: 'A user has been deleted due to user inactivity.',
  },
  {
    key: EVENT_USER_DISABLED,
    description: 'A user account has been disabled',
  },
  {
    key: EVENT_USER_ENABLED,
    description: 'A user account has been enabled',
  },
  {
    key: EVENT_CONTENT_RESERVABLE_WAITLIST_ENABLED,
    description: 'Waitlist has been enabled for a reservable content item.',
  },
  {
    key: EVENT_CONTENT_USER_WAITLIST_JOIN,
    description: 'A user has joined a waitlist for a reservable content item.',
  },
  {
    key: EVENT_CONTENT_USER_WAITLIST_LEAVE,
    description: 'A user left the waitlist of a reservable content item.',
  },
  {
    key: EVENT_CONTENT_QUANTITY_WAITLIST_ENABLED,
    description: 'Waitlist has been enabled for a quantity content item.',
  },
  {
    key: EVENT_CONTENT_QUANTITY_USER_WAITLIST_JOIN,
    description: 'A user has joined a waitlist for a quantity content item.',
  },
  {
    key: EVENT_CONTENT_QUANTITY_USER_WAITLIST_LEAVE,
    description: 'A user left the waitlist of a quantity content item.',
  },

  {
    key: EVENT_USER_WAITLIST_CONVERSION,
    description: 'A waitlisted user has converted',
  },
  {
    key: EVENT_USER_UPDATE_SESSION,
    description: 'A User has updated his session after 2FA signin',
  },
  {
    key: EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION,
    description: "A work order's service request has been escalated",
  },
  {
    key: EVENT_PIN_SECTION_CONTENT,
    description: 'A section content has been pinned to the section',
  },
  {
    key: EVENT_UNPIN_SECTION_CONTENT,
    description: 'A section content has been un-pinned from the section',
  },
  {
    key: EVENT_VIEW_BUTTON_CLICK_SECTION_CONTENT,
    description: "A section content's view button is clicked",
  },
  {
    key: EVENT_REORDER_SECTION_CONTENT,
    description: 'A section content has been reordered',
  },
  ...audienceEvents,
  ...draftContentEvents,
  ...serviceRequestEvents,
  ...taskEvents,
  ...underConstructionEvents,
];

export default events;
