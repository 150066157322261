import React from 'react';

import { Button } from '../Button/Button';
import { Icon } from '../Icon';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import type {
  Column,
  ColumnVisibilityState,
  RowSelectionState,
} from '../Table/hooks/useTable';
import { BulkAction, BulkActions } from './BulkActions';
import { ColumnVisibility } from './ColumnVisibility';
import { ExportButton, ExportOption } from './ExportButton';
import { ImportButton, ImportAction } from './ImportButton';

import styles from './DataViewControls.scss';

export type DataViewControlsProps<TDataShape extends unknown> = {
  onRefreshClick?: () => void;
  primaryActionLabel?: string;
  onPrimaryAction?: () => void;
  importAction?: ImportAction<TDataShape>;
  exportOptions?: ExportOption<TDataShape>[];
  data?: TDataShape[];
  bulkActions?: BulkAction[];
  rowSelection?: RowSelectionState;
  columns?: Column<TDataShape>[];
  columnVisibility?: ColumnVisibilityState;
  setColumnVisibility?: (columnVisibility: ColumnVisibilityState) => void;
  showBorderTop?: boolean;
  totalRows?: number;
};

const numberFormat = new Intl.NumberFormat().format;

export function DataViewControls<TDataShape>({
  exportOptions,
  importAction,
  onRefreshClick,
  columnVisibility,
  setColumnVisibility,
  columns,
  bulkActions,
  rowSelection,
  data,
  primaryActionLabel,
  onPrimaryAction,
  showBorderTop,
  totalRows,
}: DataViewControlsProps<TDataShape>) {
  const showBulkActions =
    rowSelection && Object.keys(rowSelection).length && bulkActions?.length;

  const showColumnVisibility =
    columns && setColumnVisibility && columnVisibility;

  const { t } = useTranslation();

  if (
    !showBulkActions &&
    !exportOptions &&
    !setColumnVisibility &&
    !onPrimaryAction &&
    !totalRows &&
    !importAction
  )
    return null;

  return showBulkActions ? (
    <BulkActions rowSelection={rowSelection} bulkActions={bulkActions} />
  ) : (
    <div
      className={cx(styles.ControlsWrapper, {
        [styles.ControlsWrapperBorderTop]: showBorderTop,
      })}
    >
      <div>
        {totalRows && (
          <span className={styles.numberOfItems}>
            {t('web.components.table.totalItems', {
              count: totalRows,
              totalItems: numberFormat(totalRows),
            })}
          </span>
        )}
      </div>
      <div>
        {showColumnVisibility && (
          <ColumnVisibility
            columns={columns}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
          />
        )}
        {importAction && (
          <ImportButton importAction={importAction} data={data} />
        )}
        {exportOptions && (
          <ExportButton exportOptions={exportOptions} data={data} />
        )}
        {onRefreshClick && (
          <Button
            variant="text"
            onClick={onRefreshClick}
            size="large"
            dataCy="refresh-button"
            startIcon={<Icon name="redo" set="FontAwesome" type="far" />}
          >
            {t('web.admin.channel.visitor.log.button.refresh')}
          </Button>
        )}
        {onPrimaryAction && (
          <div className={styles.PrimaryAction}>
            <Button onClick={onPrimaryAction} size="large">
              {primaryActionLabel ||
                t('web.components.table.primaryActionDefaultLabel')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
