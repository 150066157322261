import React, { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ChannelSearchByGeoLocation } from 'components';

import { getDisplayProfileName } from 'lane-shared/helpers/getDisplayName';
import LocationType from 'lane-shared/properties/baseTypes/Location';
import {
  ChannelRelationshipTypeEnum,
  ChannelTypeEnum,
} from 'lane-shared/types/ChannelType';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { pause, getDisplayName } from 'lane-shared/helpers';
import { getClient } from 'lane-shared/apollo';
import {
    addChannelRelationship,
  } from 'lane-shared/graphql/mutation';


import styles from './ChannelRelationshipsSearch.scss';

const DEFAULT_CHANNEL_TYPE = 'Property';

type SearchType = {
  name: string;
  type: ChannelTypeEnum | `${typeof DEFAULT_CHANNEL_TYPE}`;
  page: number;
  perPage: number;
};

type OwnProps = {
  setIsOpen: (isOpen: boolean) => void;
  setLoading: (loading: boolean) => void;
};

type Props = OwnProps;

const TRANSLATION_KEYS = {
    addNewConfirmTitle:
      'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewConfirm.title',
    addNewConfirmMessage:
      'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewConfirm.message',
    addNewConfirmText:
      'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewConfirm.confirmText',
    addNewSuccessToast:
      'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewSuccessToast',
    addNewErrorTitle:
      'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewError.title',
    addNewErrorMessage:
      'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewError.message',
    };

export default function AddTenantRelationship({
  setIsOpen = () => null,
  setLoading = () => null,
}: Props) {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [search, setSearch] = useState<SearchType>({
    name: '',
    type: DEFAULT_CHANNEL_TYPE,
    page: 0,
    perPage: 25,
  });
  const types = Object.values(ChannelTypeEnum);

  function updateSearch(props: Partial<SearchType>) {
    if (!props.page) {
      props.page = 0;
    }

    setSearch({
      ...search,
      ...props,
    });
  }

  async function addNewRelationship(newChannel: any) {
    try {
      const name = getDisplayProfileName(newChannel);
      await window.Alert.confirm({
        title: t(TRANSLATION_KEYS.addNewConfirmTitle, {
          channelName: name,
        }),
        message: t(TRANSLATION_KEYS.addNewConfirmMessage, {
          channelName: newChannel.name,
          targetChannel: channel?.name,
        }),
        confirmText: t(TRANSLATION_KEYS.addNewConfirmText),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    const relationship = {
      channel: {
        _id: newChannel._id,
        parent: { _id: newChannel.parent._id },
      },
      relatedTo: {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        _id: channel._id,
      },
      type: ChannelRelationshipTypeEnum.Tenant,
    };

    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: addChannelRelationship,
        variables: { relationship },
        refetchQueries: ['ChannelsByRelationship'],
      });
      window.Toast.show(
        <p>
          {t(TRANSLATION_KEYS.addNewSuccessToast, {
            channelName: newChannel.name,
          })}
        </p>
      );
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.addNewErrorTitle, {
          channelName: getDisplayName(newChannel),
        }),
        message: t(TRANSLATION_KEYS.addNewErrorMessage),
        error: err,
      });
    }

    setIsOpen(false);
    setLoading(false);
  }

  return (
    <div
      data-cy="channelSearchResults"
      className={cx(styles.ChannelRelationshipsSearch)}
    >
      {types.length > 1 && (
        <menu data-cy="channelTypeMenu">
          {types
            .filter(type => types.includes(type))
            .map(type => (
              <button
                key={type}
                data-is-selected={type === search.type}
                onClick={() => updateSearch({ type })}
              >
                {t(
                  `web.components.lane.ChannelRelationshipsSearch.ChannelTypeEnum.${type}`
                )}
              </button>
            ))}
        </menu>
      )}
      <ChannelSearchByGeoLocation
        defaultDistance={200}
        type={search.type as ChannelTypeEnum}
        excludeParentChannels
        location={{
        latitude:
            channel?.address?.geo?.[1] ?? LocationType.default.latitude,
        longitude:
            channel?.address?.geo?.[0] ?? LocationType.default.longitude,
        }}
        onChannelSelected={channel => addNewRelationship(channel)}
      />
    </div>
  );
}
