import { gql } from 'graphql-query-contracts';

export const getContentQuantityClaimedByUser =  gql(/* GraphQL */ `
  query getContentQuantityClaimedByUser(
    $contentId: UUID!
  ) {
    contentQuantityClaimedByUser(
      contentId: $contentId
    ) 
  }
`);
