import React, { useState, useEffect, useCallback } from 'react';
import * as yup from 'yup';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { NotesType } from 'lane-shared/domains/visitorManagement/types';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import TextArea from 'components/form/TextArea';
import { H5, S } from 'components/typography';

import styles from './NotesForm.scss';
import { TagInput } from 'design-system-web';

export function NotesForm({
  className,
  properties,
  onChange,
  dataCy,
  submissionCompletedAt,
  isPreview = false,
}: {
  className?: string;
  properties: VisitorManagementFeatureProperties;
  onChange: (value: NotesType) => void;
  dataCy?: string;
  submissionCompletedAt?: Date;
  isPreview?: boolean;
}) {
  useEffect(() => {
    resetForm();
  }, [submissionCompletedAt]);

  const { t } = useTranslation();

  const [notesToStaff, setNotesToStaff] = useState<string>('');
  const [notesToVisitor, setNotesToVisitor] = useState<string>('');
  const [additionalRecipients, setAdditionalRecipients] = useState<string[]>(
    []
  );

  const emailValidator = yup.string().email().nullable();

  function handleNotesToStaff(notesToStaffValue: string) {
    setNotesToStaff(notesToStaffValue);

    onChange({
      noteToVisitor: notesToVisitor ?? undefined,
      noteToStaff: notesToStaffValue,
      additionalRecipients: additionalRecipients?.length
        ? additionalRecipients
        : [],
    });
  }

  function handleNotesToVisitor(notesToVisitorValue: string) {
    setNotesToVisitor(notesToVisitorValue);

    onChange({
      noteToVisitor: notesToVisitorValue,
      noteToStaff: notesToStaff ?? undefined,
      additionalRecipients: additionalRecipients?.length
        ? additionalRecipients
        : [],
    });
  }

  const handleAdditionalRecipients = useCallback(
    (recipients: string[]) => {
      setAdditionalRecipients(recipients);

      onChange({
        noteToVisitor: notesToVisitor ?? '',
        noteToStaff: notesToStaff ?? '',
        additionalRecipients: recipients,
      });
    },
    [notesToVisitor, notesToStaff, onChange]
  );

  const resetForm = useCallback(() => {
    setNotesToVisitor('');
    setNotesToStaff('');
    setAdditionalRecipients([]);

    onChange({
      noteToVisitor: '',
      noteToStaff: '',
      additionalRecipients: [],
    });
  }, [onChange]);

  const validateEmail = (email: string) => {
    try {
      emailValidator.validateSync(email);
      return true;
    } catch (err) {
      return false;
    }
  };

  return (
    <div className={cx(className, styles.notesForm)} data-cy={dataCy}>
      {properties.notesToStaffEnabled && (
        <div className={styles.inputContainer}>
          <H5>
            {t('web.content.feature.visitorManagement.notes.staff.title')}
          </H5>
          <S className={styles.description}>
            {t('web.content.feature.visitorManagement.notes.staff.description')}
          </S>
          <TextArea
            className={styles.textArea}
            placeholder={t(
              'web.content.feature.visitorManagement.notes.staff.placeholder'
            )}
            value={notesToStaff}
            onChange={value => handleNotesToStaff(value)}
            maxLength={200}
            dataCy="notesToStaffInput"
            disabled={isPreview}
            showLengthIndicator
          />
        </div>
      )}
      {properties.notesToVisitorEnabled && (
        <div className={styles.inputContainer}>
          <H5>
            {t('web.content.feature.visitorManagement.notes.visitor.title')}
          </H5>
          <S className={styles.description}>
            {t(
              'web.content.feature.visitorManagement.notes.visitor.description'
            )}
          </S>
          <TextArea
            className={styles.textArea}
            placeholder={t(
              'web.content.feature.visitorManagement.notes.visitor.placeholder'
            )}
            value={notesToVisitor}
            onChange={value => handleNotesToVisitor(value)}
            maxLength={200}
            dataCy="notesToVisitorInput"
            disabled={isPreview}
            showLengthIndicator
          />
        </div>
      )}
      {properties.additionalRecipientsEnabled && (
        <div className={styles.additionalRecipientsInputContainer}>
          <H5>
            {t(
              'shared.content.feature.visitorManagement.additional.recipients.notify.title'
            )}
          </H5>
          <S className={styles.additionalRecipientsDescription}>
            {t(
              'shared.content.feature.visitorManagement.additional.recipients.notify.description'
            )}
          </S>
          <TagInput
            tags={additionalRecipients}
            setTags={handleAdditionalRecipients}
            validateTag={validateEmail}
            placeholder={t(
              'shared.content.feature.visitorManagement.additional.recipients.notify.input.placeholder'
            )}
            maxTags={20}
            maxTagInfo={t(
              'shared.content.feature.visitorManagement.additional.recipients.notify.input.maximum.email'
            )}
            errorText={t(
              'shared.content.feature.visitorManagement.additional.recipients.notify.input.error'
            )}
          />
        </div>
      )}
    </div>
  );
}
