import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { queryChannels } from 'lane-shared/graphql/channel';
import { getDisplayName } from 'lane-shared/helpers';
import { CONTENT_TYPES } from 'lane-shared/helpers/constants/content';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { COMMUNITY_CHANNEL } from 'lane-shared/helpers/constants/ids';
import { constructPlacement } from 'lane-shared/helpers/content';
import { convertTo62 } from 'lane-shared/helpers/convertId';
import {
  useChannelProfileQuery,
  useContentTargetingAudienceSize,
} from 'lane-shared/hooks';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { GroupRole } from 'lane-shared/types/GroupRole';
import { useAudienceAnalytics } from 'lane-shared/hooks/analytics';
import type { Audience } from 'lane-shared/types/audiences/Audience';

import { DangerousTranslate } from 'lane-web/src/components/DangerousTranslate';

import Checkbox from 'components/form/Checkbox';
import { Button } from 'components/general';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import ChannelCircleListView from 'components/lane/ChannelCircleListView';
import ChannelRelationshipsSearch from 'components/lane/ChannelRelationshipsSearch';
import ChannelSelector from 'components/lane/ChannelSelector';
import ContentPlacementListItem from 'components/lane/ContentPlacementListItem';
import GroupRoleSelector from 'components/lane/GroupRoleSelector';
import { Flex } from 'components/layout';
import { Modal } from 'components/lds';
import { H4 } from 'components/typography';

import { AudienceModal } from './AudienceModal';
import DraftContentTargetingIntegrations from './DraftContentTargetingIntegrations';

import styles from './DraftContentTargetingPlacements.scss';

// dumb cache to prevent multiple look ups
const channelCache = {} as Record<string, any>;

function ChannelListView({ channelId }: any) {
  // get the selected channel info to display.
  const { channel } = useChannelProfileQuery({
    channelId,
  });

  channelCache[channelId] = channel;

  return <ChannelCircleListView channel={channel} />;
}

export default function DraftContentTargetingPlacements({
  user,
  hasChanged,
  channel,
  content,
  liveContent,
  onContentUpdated,
  stepperTracker,
}: any) {
  const { t } = useTranslation();
  const [teamChannelId] = useState(channel?._id);
  const [isTeamsOpen, setIsTeamsOpen] = useState(false);
  const [isRelationshipsOpen, setIsRelationshipsOpen] = useState(false);
  const [isSubChannelsOpen, setIsSubChannelsOpen] = useState(false);
  const [isChannelsOpen, setIsChannelsOpen] = useState(false);
  const [isAudienceOpen, setIsAudienceOpen] = useState(false);
  const [audienceSize] = useContentTargetingAudienceSize(content);

  const [groupRoleTarget, setGroupRoleTarget] = useState<GroupRole | null>(
    null
  );
  const subChannelsResult = useQuery(queryChannels, {
    variables: {
      pagination: {
        start: 0,
        perPage: 100,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
        isSub: true,
        parent: {
          _id: channel?._id,
        },
      },
    },
  });

  const { audienceTracker } = useAudienceAnalytics();

  const addAudience = (audienceId: string) => {
    onContentUpdated({
      audiences: uniqBy(
        [...(content.audiences || []), { id: audienceId }],
        'id'
      ),
    });
    setIsAudienceOpen(false);
  };

  const removeAudience = (audienceId: string) => {
    onContentUpdated({
      audiences: content.audiences.filter(
        (audience: { id: string }) => audience.id !== audienceId
      ),
    });
  };

  const subChannels = subChannelsResult?.data?.channels?.items || [];

  const isSendingToChannel =
    channel?._id &&
    content?.placements?.find(
      (placement: any) => placement.channel?._id === channel._id
    );

  const isSendingToCommunity = content?.placements?.find(
    (placement: any) =>
      placement.channel?._id === convertTo62(COMMUNITY_CHANNEL)
  );

  function addGroupRolePlacement(groupRole: any) {
    if (
      content.placements.some(
        (placement: any) => placement.groupRole?._id === groupRole._id
      )
    ) {
      return;
    }

    const placement = {
      ...constructPlacement({ userId: user._id, contentId: content._id }),
      groupRole: {
        _id: groupRole._id,
      },
    };

    onContentUpdated({ placements: [...content.placements, placement] });
  }

  function addChannelPlacement(channel: any) {
    if (!content.placements) {
      content.placements = [];
    }

    if (
      content.placements.some(
        (placement: any) => placement.channel?._id === channel._id
      )
    ) {
      return;
    }

    const placement = {
      ...constructPlacement({ userId: user._id, contentId: content._id }),
      channel: {
        _id: channel._id,
      },
    };

    onContentUpdated({ placements: [...content.placements, placement] });
    setIsChannelsOpen(false);
  }

  function removeCommunity() {
    onContentUpdated({
      placements: content.placements.filter(
        (p: any) =>
          !p.channel || p.channel._id !== convertTo62(COMMUNITY_CHANNEL)
      ),
    });
  }

  function removePlacement(placement: any) {
    return onContentUpdated({
      placements: content.placements.filter(
        (p: any) => p._id !== placement._id
      ),
    });
  }

  function confirmNavigateAway(e: any) {
    if (hasChanged) {
      window.Toast.show(
        t('web.admin.content.draftContent.unsavedChangesToast')
      );
      e.preventDefault();
      return false;
    }
    return true;
  }

  // community channel is a special button
  const placements =
    content?.placements?.filter(
      (placement: any) =>
        placement?.channel?._id !== convertTo62(COMMUNITY_CHANNEL)
    ) || [];

  const channelPlacements = placements.filter(({ channel }: any) => channel);

  const groupPlacements = placements.filter(({ groupRole }: any) => groupRole);

  const userPlacements = placements.filter(({ user }: any) => user);

  const isPlaceable = ![CONTENT_TYPES.PROMOTION].includes(content.type);

  const canBePlacedToCommunity = [
    ChannelTypeEnum.Meta,
    ChannelTypeEnum.Curated,
  ].includes(channel?.type);

  // re-order all the sections this content is placed in by channel
  const channelSections = liveContent?.sectionContent?.reduce(
    (channelSections: any, sectionContent: any) => {
      const existingChannel = channelSections.find(
        ({ channel }: any) => channel._id === sectionContent.section.channel._id
      );

      if (!existingChannel) {
        return [
          ...channelSections,
          {
            channel: sectionContent.section.channel,
            sectionContents: [sectionContent],
          },
        ];
      }

      existingChannel.sectionContents = [
        ...existingChannel.sectionContents,
        sectionContent,
      ];

      return channelSections;
    },
    []
  );

  const channelDisplayName = getDisplayName(channel);

  return (
    <section className={styles.DraftContentTargetingPlacements}>
      {isPlaceable && (
        <>
          {content.placements && (
            <div data-cy="contentReachNumber" className={styles.panel}>
              <h1>
                <DangerousTranslate
                  translationKey="web.admin.content.draftContent.audienceSize"
                  values={{
                    count: audienceSize,
                  }}
                />
              </h1>
            </div>
          )}
          <div className={styles.panel}>
            <div className={styles.group}>
              <Checkbox
                dataCy="sendToEveryoneCheckbox"
                selected={!!isSendingToChannel}
                value={!!isSendingToChannel}
                text={t('web.admin.content.draftContent.checkboxLabel', {
                  channelDisplayName,
                })}
                onChange={() =>
                  isSendingToChannel
                    ? removePlacement(isSendingToChannel)
                    : addChannelPlacement(channel)
                }
              />

              {channelPlacements.length > 0 && (
                <>
                  <h1>
                    {t('web.admin.content.draftContent.channelsTitle', {
                      count: channelPlacements.length,
                    })}
                  </h1>
                  <ul data-cy="contentChannelReach">
                    {channelPlacements.map((placement: any) => (
                      <ContentPlacementListItem
                        key={placement._id}
                        className={styles.placement}
                        channelId={placement.channel?._id}
                        onRemovePlacement={() => {
                          const newDraft = removePlacement(placement);
                          stepperTracker?.Targeting.Remove('Channel', newDraft);
                        }}
                      />
                    ))}
                  </ul>
                </>
              )}

              {groupPlacements.length > 0 && (
                <>
                  <h1>
                    {t('web.admin.content.draftContent.teamsTitle', {
                      count: groupPlacements.length,
                    })}
                  </h1>
                  <ul data-cy="contentTeamReach">
                    {groupPlacements.map((placement: any) => (
                      <ContentPlacementListItem
                        key={placement._id}
                        className={styles.placement}
                        groupRoleId={placement.groupRole?._id}
                        onRemovePlacement={() => {
                          const newDraft = removePlacement(placement);
                          stepperTracker?.Targeting.Remove('Team', newDraft);
                        }}
                      />
                    ))}
                  </ul>
                </>
              )}

              {userPlacements.length > 0 && (
                <>
                  <h1>
                    {t('web.admin.content.draftContent.usersTitle', {
                      count: userPlacements.length,
                    })}
                  </h1>
                  <ul data-cy="contentUserReach">
                    {userPlacements.map((placement: any) => (
                      <ContentPlacementListItem
                        key={placement._id}
                        className={styles.placement}
                        userId={placement.user?._id}
                        onRemovePlacement={() => {
                          const newDraft = removePlacement(placement);
                          stepperTracker?.Targeting.Remove('User', newDraft);
                        }}
                      />
                    ))}
                  </ul>
                </>
              )}
              {content.audiences?.length > 0 && (
                <>
                  <h1>
                    {t('web.admin.content.draftContent.audiencesTitle', {
                      count: content.audiences.length,
                    })}
                  </h1>
                  <ul data-cy="contentAudienceReach">
                    {content.audiences.map((audience: { id: string }) => (
                      <ContentPlacementListItem
                        key={audience.id}
                        className={styles.placement}
                        audienceData={{
                          audienceId: audience.id,
                          channelId: channel?._id,
                        }}
                        onRemovePlacement={(removedAudience: Audience) => {
                          audienceTracker.Target.Remove(removedAudience);
                          removeAudience(audience.id);
                        }}
                      />
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>

          <div className={styles.panel}>
            <button
              onClick={() => {
                audienceTracker.Target.Start();
                setIsAudienceOpen(true);
              }}
            >
              {t('web.admin.content.draftContent.targetAudienceButton')}
              <Icon
                dataCy="targetAudience"
                name="plus-circle"
                set={ICON_SET_FONTAWESOME}
                className={styles.addIcon}
              />
            </button>

            <button
              onClick={() => {
                setIsSubChannelsOpen(true);
                stepperTracker?.Targeting.Add('Channel', content);
              }}
            >
              {t('web.admin.content.draftContent.targetSubchannelButton', {
                channelDisplayName,
              })}
              <Icon
                dataCy="targetSubChannel"
                name="plus-circle"
                set={ICON_SET_FONTAWESOME}
                className={styles.addIcon}
              />
            </button>

            <button
              onClick={() => {
                setIsRelationshipsOpen(true);
                stepperTracker?.Targeting.Add('Channel', content);
              }}
            >
              {t('web.admin.content.draftContent.targetTennantButton', {
                channelDisplayName,
              })}
              <Icon
                dataCy="targetTenantChannel"
                name="plus-circle"
                set={ICON_SET_FONTAWESOME}
                className={styles.addIcon}
              />
            </button>

            <button
              onClick={() => {
                setIsTeamsOpen(true);
                stepperTracker?.Targeting.Add('Team', content);
              }}
            >
              {t('web.admin.content.draftContent.targetTeamButton')}
              <Icon
                dataCy="targetTeam"
                name="plus-circle"
                set={ICON_SET_FONTAWESOME}
                className={styles.addIcon}
              />
            </button>

            <button
              onClick={() => {
                setIsChannelsOpen(true);
                stepperTracker?.Targeting.Add('Channel', content);
              }}
            >
              {t('web.admin.content.draftContent.targetChannelButton')}
              <Icon
                dataCy="targetChannel"
                name="plus-circle"
                set={ICON_SET_FONTAWESOME}
                className={styles.addIcon}
              />
            </button>
          </div>
        </>
      )}

      {canBePlacedToCommunity && (
        <div className={styles.panel}>
          <Checkbox
            selected={!!isSendingToCommunity}
            value={!!isSendingToCommunity}
            text={t('web.admin.content.draftContent.communityCheckbox')}
            onChange={() =>
              isSendingToCommunity
                ? removeCommunity()
                : addChannelPlacement({
                    _id: convertTo62(COMMUNITY_CHANNEL),
                  })
            }
          />
        </div>
      )}

      {channelSections?.length > 0 && (
        <>
          <H4 mb={2}>{t('web.admin.content.draftContent.sectionsTittle')}</H4>
          {channelSections.map((channelSection: any) => (
            <div className={styles.panel} key={channelSection.channel._id}>
              <div className={styles.group}>
                <ChannelListView channelId={channelSection.channel._id} />
                <ul>
                  {channelSection.sectionContents?.map(
                    (sectionContent: any) => (
                      <li
                        key={sectionContent._id}
                        className={styles.sectionPlacement}
                      >
                        <Link
                          onClick={confirmNavigateAway}
                          to={routes.channelAdminSectionsView
                            .replace(
                              ':id',
                              channelCache[sectionContent.section.channel._id]
                                ?.slug
                            )
                            .replace(':sectionId', sectionContent.section._id)}
                        >
                          {sectionContent.section.name}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          ))}
        </>
      )}

      <DraftContentTargetingIntegrations
        channel={channel}
        content={content}
        hasChanged={hasChanged}
        onContentUpdated={onContentUpdated}
      />

      <ModalBackground
        className={styles.background}
        isOpen={isSubChannelsOpen}
        onClose={() => setIsSubChannelsOpen(false)}
      >
        <ResizableWindow
          className={styles.window}
          name="DraftContentTargetingSubChannels"
          onClose={() => setIsSubChannelsOpen(false)}
          showHeader
        >
          <ul>
            {subChannels.map((channel: any) => (
              <button
                key={channel._id}
                className={styles.placementOption}
                onClick={() => {
                  addChannelPlacement(channel);
                  setIsSubChannelsOpen(false);
                  stepperTracker?.Targeting.Select('Channel', content);
                }}
              >
                <ChannelCircleListView channel={channel} />
              </button>
            ))}
          </ul>
        </ResizableWindow>
      </ModalBackground>

      <Modal
        title={t('web.admin.content.draftContent.teamModalTittle')}
        isOpen={isTeamsOpen}
        onClose={() => {
          setGroupRoleTarget(null);
          setIsTeamsOpen(false);
        }}
        size="small"
        style={{
          height: '60vh',
        }}
        actions={
          <Flex gap={2}>
            <Button
              onClick={() => {
                setIsTeamsOpen(false);
                setGroupRoleTarget(null);
              }}
            >
              {t('web.admin.content.draftContent.modalCancelButton')}
            </Button>
            <Button
              variant="contained"
              disabled={!groupRoleTarget}
              onClick={() => {
                addGroupRolePlacement(groupRoleTarget);
                setIsTeamsOpen(false);
                setGroupRoleTarget(null);
                stepperTracker?.Targeting.Select('Team', content);
              }}
            >
              {t('web.admin.content.draftContent.modalSelectButton')}
            </Button>
          </Flex>
        }
      >
        <Flex direction="column" gap={5}>
          <GroupRoleSelector
            groupRoleId={groupRoleTarget?._id}
            channelId={teamChannelId}
            style={{
              flexDirection: 'column',
            }}
            channelSelectorButtonStyle={{
              maxWidth: '100%',
              marginBottom: '1em',
              marginRight: '0em',
            }}
            onGroupRoleSelected={groupRole => {
              setGroupRoleTarget(groupRole);
            }}
          />
        </Flex>
      </Modal>

      <ModalBackground
        className={styles.background}
        isOpen={isRelationshipsOpen}
        onClose={() => setIsRelationshipsOpen(false)}
      >
        <ResizableWindow
          className={styles.window}
          name="DraftContentTargetingRelationships"
          onClose={() => setIsRelationshipsOpen(false)}
          showHeader
        >
          <ChannelRelationshipsSearch
            className={styles.relationships}
            channelId={channel?._id}
            renderRelationship={relationship => (
              <button
                key={relationship._id}
                className={styles.placementOption}
                onClick={() => {
                  addChannelPlacement(relationship.channel);
                  setIsRelationshipsOpen(false);
                  stepperTracker?.Targeting.Select('Channel', content);
                }}
              >
                {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message */}
                <ChannelCircleListView channel={relationship.channel} />
              </button>
            )}
          />
        </ResizableWindow>
      </ModalBackground>

      <ModalBackground
        className={styles.background}
        isOpen={isChannelsOpen}
        onClose={() => setIsChannelsOpen(false)}
      >
        <ResizableWindow
          className={styles.window}
          name="DraftContentTargeting"
          onClose={() => setIsChannelsOpen(false)}
          showHeader
        >
          <ChannelSelector
            storageKey="DraftContentTargetingChannelSelector"
            className={styles.channelSelector}
            onChannelSelected={(channel: any) => {
              addChannelPlacement(channel);
              stepperTracker?.Targeting.Select('Channel', content);
            }}
          />
        </ResizableWindow>
      </ModalBackground>

      <AudienceModal
        addAudience={addAudience}
        setIsModalOpen={setIsAudienceOpen}
        isModalOpen={isAudienceOpen}
        channelId={channel?._id}
      />
    </section>
  );
}
