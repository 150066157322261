import React, { useCallback } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { BLOCK_CONTAINERS } from 'lane-shared/helpers/constants/blocks';

import styles from './BlockWireFrame.scss';

type OwnProps = {
  block: any;
  isTop?: boolean;
  focusBlock?: any;
  activeBlock?: any;
  dragOverBlock?: any;
};

type Props = OwnProps;

function BlockWireFrame({
  block,
  isTop = false,
  focusBlock = null,
  activeBlock = null,
  dragOverBlock = null,
}: Props) {
  const { t } = useTranslation();

  // create using useCallback to prevent re-rendering.
  const handleEditEvent = useCallback((e: any) => {
    if (e.type === 'dragover') {
      e.preventDefault();
    }

    if (!e.nativeEvent.data || !e.nativeEvent.data.blockId) {
      e.nativeEvent.data = { blockId: block._id, action: 'move' };
    }
  }, []);

  if (!block) {
    return null;
  }

  const children = (block.children || []).map((child: any, i: number) => (
    <BlockWireFrame
      key={child.key || i}
      block={child}
      focusBlock={focusBlock}
      activeBlock={activeBlock}
      dragOverBlock={dragOverBlock}
    />
  ));

  if (block.isPrimitive && !block.editable) {
    return children;
  }

  const getTranslatableBlockName = (name: string) => {
    if (!name || name.length === 0) {
      return t(`web.admin.channel.content.layout.editor.block.block`);
    }

    const translationPrefix = 'web.admin.channel.content.layout.editor.block.';
    const concatenatedName = name.replace(/ /g, '');
    const translatableName =
      concatenatedName.charAt(0).toLowerCase() + concatenatedName.slice(1);

    const translatedBlockName = t(`${translationPrefix}${translatableName}`);

    if (translatedBlockName.includes(translationPrefix)) {
      return t('web.admin.channel.content.layout.editor.properties.label', {
        property: name,
      });
    }

    return translatedBlockName;
  };

  return (
    <div
      className={styles.BlockWireFrame}
      data-horizontal-container={
        block.name === BLOCK_CONTAINERS.BLOCK_CONTAINER_ROW
      }
      data-vertical-container={
        block.name === BLOCK_CONTAINERS.BLOCK_CONTAINER_COLUMN
      }
      role="button"
      data-is-top={isTop}
      data-is-focused={focusBlock && focusBlock._id === block._id}
      data-is-active={
        focusBlock &&
        activeBlock &&
        activeBlock._id === block._id &&
        activeBlock._id === focusBlock._id
      }
      data-is-dragover={dragOverBlock && dragOverBlock._id === block._id}
      tabIndex={0}
      onKeyPress={e => e.key === Key.Enter && handleEditEvent(e)}
      onClick={handleEditEvent}
      onFocus={handleEditEvent}
      onMouseOver={handleEditEvent}
      onDrag={handleEditEvent}
      onDragStart={handleEditEvent}
      onDragEnter={handleEditEvent}
      onDragOver={handleEditEvent}
      onDragLeave={handleEditEvent}
      onDrop={handleEditEvent}
      draggable={!isTop}
    >
      <label data-block-id={block._id}>
        {getTranslatableBlockName(block.name)}
      </label>
      {block.hasLink && (
        <Icon dataCy="linkedBlock" name="link" className={styles.icon} />
      )}
      <div className={styles.children}>{children}</div>
    </div>
  );
}

export default BlockWireFrame;
