import React, { useState } from 'react';

import { Icon } from 'design-system-web';

import { BLOCK_LOCK_MODES } from 'lane-shared/helpers/constants/blocks';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import styles from './BlockEditFrame.scss';

const DEFAULT_POSITION = {
  x: 0,
  y: 0,
  width: 200,
  height: 200,
};

function BlockEditMenu({
  block,
  forTemplate,
  onDeleteBlock,
  onBlockClone,
  onBlockUpdated,
  onBlockMoveUp,
  onBlockMoveDown,
  setIsPropertiesOpen,
  setIsLinkOpen,
  setIsMoving,
  isLinkOpen,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  position = DEFAULT_POSITION,
}: any) {
  const [showMore, setShowMore] = useState(false);
  const locks = block.lock || [];

  const isMoveLocked =
    locks.includes(BLOCK_LOCK_MODES.ALL) ||
    locks.includes(BLOCK_LOCK_MODES.POSITION);

  const isDeleteLocked =
    locks.includes(BLOCK_LOCK_MODES.ALL) ||
    locks.includes(BLOCK_LOCK_MODES.DELETE);

  const isCloneLocked =
    locks.includes(BLOCK_LOCK_MODES.ALL) ||
    locks.includes(BLOCK_LOCK_MODES.CLONE);

  const isLinkLocked =
    locks.includes(BLOCK_LOCK_MODES.ALL) ||
    locks.includes(BLOCK_LOCK_MODES.PROPERTIES);

  function handleEditEvent(e: any) {
    switch (e.type) {
      case 'dragover':
        e.preventDefault();
        break;
      case 'dragstart':
        setIsMoving(true);
        break;
      case 'dragend':
        setIsMoving(false);
        break;
      default:
        break;
    }

    if (!e.nativeEvent.data || !e.nativeEvent.data.blockId) {
      e.nativeEvent.data = { blockId: block._id, action: 'move' };
    }
  }

  const lockButtons: any = [];

  if (forTemplate) {
    Object.values(BLOCK_LOCK_MODES)
      .filter(
        mode => ![BLOCK_LOCK_MODES.ALL, BLOCK_LOCK_MODES.STYLE].includes(mode)
      )
      .forEach(mode => {
        const isLocked =
          block.lock &&
          block.lock.some((lock: any) =>
            [BLOCK_LOCK_MODES.ALL, mode].includes(lock)
          );

        lockButtons.push(
          <button
            key={mode}
            onClick={() => {
              if (!block.lock) {
                block.lock = [];
              }

              if (isLocked) {
                block.lock = block.lock.filter((m: any) => m !== mode);
              } else {
                block.lock.push(mode);
              }

              onBlockUpdated();
            }}
          >
            <Icon
              className={styles.icon}
              name={isLocked ? 'lock' : 'lock-open'}
              set={ICON_SET_FONTAWESOME}
            />
            <label>{mode}</label>
          </button>
        );
      });
  }

  return (
    <menu className={styles.handle}>
      <button
        data-cy="moveUp"
        className={styles.arrow}
        onClick={() => onBlockMoveUp(block)}
      >
        <Icon className={styles.icon} name="chevron-up" />
      </button>
      {(forTemplate || !isMoveLocked) && (
        <button
          data-cy="dragger"
          draggable
          onDragStart={handleEditEvent}
          onDragEnd={handleEditEvent}
          onDrag={handleEditEvent}
        >
          <Icon className={styles.icon} name="arrows-alt" />
          <label>Move</label>
        </button>
      )}

      {(forTemplate || !isDeleteLocked) && (
        <button data-cy="deleteBlock" onClick={() => onDeleteBlock(block)}>
          <Icon
            className={styles.icon}
            name="trash"
            set={ICON_SET_FONTAWESOME}
          />
          <label>Delete</label>
        </button>
      )}

      {(forTemplate || !isLinkLocked) && (
        <button
          data-cy="linkBlock"
          onClick={() => {
            setIsPropertiesOpen(isLinkOpen);
            setIsLinkOpen(!isLinkOpen);
          }}
          data-is-active={!!block.link}
          data-is-selected={isLinkOpen}
        >
          <Icon className={styles.icon} name="link" />
          <label>Link</label>
        </button>
      )}

      {!showMore ? (
        <button onClick={() => setShowMore(!showMore)}>
          <Icon
            className={styles.icon}
            name="ellipsis-h"
            set={ICON_SET_FONTAWESOME}
          />
        </button>
      ) : (
        <>
          {lockButtons}

          {(forTemplate || !isCloneLocked) && (
            <button onClick={e => onBlockClone({ e, block })}>
              <Icon className={styles.icon} name="copy" />
              <label>Clone</label>
            </button>
          )}

          <button onClick={() => setShowMore(!showMore)}>
            <Icon
              className={styles.icon}
              name="ellipsis-h"
              set={ICON_SET_FONTAWESOME}
            />
          </button>
        </>
      )}
      <button
        data-cy="moveDown"
        className={styles.arrow}
        onClick={() => onBlockMoveDown(block)}
      >
        <Icon className={styles.icon} name="chevron-down" />
      </button>
    </menu>
  );
}

export default BlockEditMenu;
