import { LaneType } from 'common-types';
import { DocumentType } from './DocumentType';
import { UserGroupRoleType } from './UserGroupRole';
import { UserLoginType } from './UserLogin';

export type UserType = DocumentType &
  Omit<PublicUserType, 'roles'> & {
    isSuperUser: boolean;
    locale: string;
    status: UserStatusEnum;
    settings: UserSettingsType;
    logins: UserLoginType[];
    lastLogin: Date;
    roles: UserGroupRoleType[];
    requestAccountDeletionByDate: Date;
    userChannelSettings: UserChannelSettingsType;
    primaryLocation: {
      _id: LaneType.UUID;
    };
    privateKey: string;
    publicKey: string;
  };

export type UserSettingsType = {
  emailOptIn: boolean;
  previousPaymentAccountId: string | null;
};

export enum UserStatusEnum {
  System = 'System',
  Active = 'Active',
  Disabled = 'Disabled',
}

export type PublicUserType = {
  _id: string;
  _created: Date;
  _updated: Date;
  name: string;
  lastSeen: Date;
  role: PublicUserGroupRoleType;
  roles: PublicUserGroupRoleType[];
  profile: PublicProfileType;
  online: boolean;
};

export type PublicUserGroupRoleType = {
  _id: string;
  channel: string;
  name?: string;
  description?: string;
  user: String;
  groupRole: string;
  groupRoleName: string;
  expires?: Date;
};

export type PublicProfileType = {
  _id: string;
  _updated: Date;
  name: string;
  description: string;
  email: string;
  phone: string;
  isPublic: boolean;
  image: string | null;
  logo: string | null;
  color: string;
  backgroundColor: string;
};

export type InitializedUserResponseType = {
  me: {
    user: Partial<UserType> & {
      logins: Partial<UserLoginType> &
        {
          __typename?: 'UserLogin';
        }[];
    };
    __typename?: 'Me';
  };
};

export type UserMobileAccess = {
  enabled: boolean;
};

export type UserChannelSettingsType = {
  id: LaneType.UUID;
  createdAt: Date;
  updatedAt: Date;
  createdBy: LaneType.UUID;
  updatedBy: LaneType.UUID;
  channelId: LaneType.UUID;
  userId: LaneType.UUID;
  language: string;
};

export enum PersonUnitRelationshipEnum {
  OCCUPIES = 'OCCUPIES',
  MANAGES = 'MANAGES',
  OWNS = 'OWNS',
  LEASES = 'LEASES',
}
